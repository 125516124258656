import React, { ReactElement, useContext } from 'react';
import { StyleSheet, View } from 'react-native';

import StyledButton, {
  ButtonColors,
  ButtonTypes,
} from 'components/StyledButton';
import StyledText from 'components/StyledText';

import AuthContext from 'services/auth/AuthContext';

import LoginIllustration from 'assets/images/LoginIlustration';
import NetlightWhite from 'assets/images/NetlightWhite';

import COLORS from 'styles/colors';

function LoginScreen(): ReactElement {
  const { signIn } = useContext(AuthContext);

  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <StyledText style={styles.pretitle}>Welcome to</StyledText>
        <StyledText style={styles.title}>BoidBook 🦄</StyledText>
      </View>

      <View style={styles.imageContainer}>
        <LoginIllustration style={styles.loginPicture} />
      </View>

      <View style={styles.section}>
        <StyledButton
          fullWidth
          type={ButtonTypes.FILLED}
          color={ButtonColors.CONFIRM}
          onPress={signIn}
          title="Log in using Netlight account"
        />
        <NetlightWhite style={styles.netlightLogo} />
      </View>
    </View>
  );
}

export default LoginScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    //padding: 20,
    backgroundColor: COLORS.PRIMARY,
  },
  section: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  pretitle: {
    marginTop: 60,
    fontSize: 24,
    color: 'white',
  },
  title: {
    fontSize: 40,
    fontWeight: 'bold',
    color: 'white',
  },
  netlightLogo: {
    margin: 10,
    marginTop: 60,
    width: 80,
  },
  imageContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginPicture: {
    width: '100%',
  },
});
