import AsyncStorage from '@react-native-async-storage/async-storage';
import { exchangeCodeAsync, refreshAsync } from 'expo-auth-session';

import AuthConfig from './AuthConfig';

async function handleSignInResult(
  authCode: string,
  redirectUri: string,
  codeVerifier: string | undefined,
): Promise<string> {
  const {
    accessToken,
    refreshToken: newRefreshToken,
    expiresIn,
  } = await exchangeCodeAsync(
    {
      code: authCode,
      clientId: AuthConfig.clientId,
      redirectUri,
      extraParams: {
        code_verifier: codeVerifier || '',
      },
    },
    {
      tokenEndpoint: AuthConfig.tokenUrl,
    },
  );

  const expiryTime = Date.now() + (expiresIn ?? 0) * 1000;

  await AsyncStorage.setItem('accessToken', accessToken);
  await AsyncStorage.setItem('refreshToken', newRefreshToken ?? '');
  await AsyncStorage.setItem('accessTokenExpiryTime', expiryTime.toString());

  return accessToken;
}

async function signOut(): Promise<void> {
  await AsyncStorage.removeItem('accessToken');
  await AsyncStorage.removeItem('refreshToken');
  await AsyncStorage.removeItem('accessTokenExpiryTime');
}

async function refreshToken(): Promise<string | null> {
  const currentRefreshToken = await AsyncStorage.getItem('refreshToken');

  // Check if the refresh token is not set OR empty
  if (!currentRefreshToken) {
    return null;
  }

  try {
    const {
      accessToken,
      refreshToken: newRefreshToken,
      expiresIn,
    } = await refreshAsync(
      {
        refreshToken: currentRefreshToken,
        clientId: AuthConfig.clientId,
      },
      {
        tokenEndpoint: AuthConfig.tokenUrl,
      },
    );

    const expiryTime = Date.now() + (expiresIn ?? 0) * 1000;

    await AsyncStorage.setItem('accessToken', accessToken);
    await AsyncStorage.setItem('refreshToken', newRefreshToken ?? '');
    await AsyncStorage.setItem('accessTokenExpiryTime', expiryTime.toString());

    return accessToken;
  } catch (err) {
    await AsyncStorage.removeItem('accessToken');
    await AsyncStorage.removeItem('refreshToken');

    return null;
  }
}

async function getValidAccessToken(): Promise<string | null> {
  const currentAccessToken = await AsyncStorage.getItem('accessToken');

  if (!currentAccessToken) {
    return null;
  }

  const rawExpiryTime = await AsyncStorage.getItem('accessTokenExpiryTime');
  const expiryTime = rawExpiryTime && Number.parseInt(rawExpiryTime, 10);

  if (!expiryTime) {
    return null;
  }

  const now = Date.now();

  if (now >= expiryTime - 1000) {
    // Token expired or less than a second till it expires -> Refresh first and return new token
    return refreshToken();
  } else if (now >= expiryTime - 5 * 60 * 1000) {
    // Token will expire in 5 minutes, return current token now but trigger a background refresh
    refreshToken();
    return currentAccessToken;
  } else {
    // Still enough time with the current token
    return currentAccessToken;
  }
}

export default {
  handleSignInResult,
  signOut,
  refreshToken,
  getValidAccessToken,
};
