import Config from 'config';

import UserProfileModel from 'models/UserProfileModel';

import AuthManager from 'services/auth/AuthManager';

const baseUrl = `${Config.apiBaseUrl}/user`;

export async function upsertUserProfile() {
  const accessToken = await AuthManager.getValidAccessToken();

  await fetch(`${baseUrl}/user-profiles`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export async function getUserProfile(
  profileId: string,
): Promise<UserProfileModel> {
  const accessToken = await AuthManager.getValidAccessToken();
  const response = await fetch(`${baseUrl}/user-profiles/${profileId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const body = response.json();

  return body;
}

async function getMyUserProfile(): Promise<UserProfileModel> {
  return getUserProfile('me');
}

async function getUserImage(profileId: string) {
  try {
    const accessToken = await AuthManager.getValidAccessToken();

    const response = await fetch(`${baseUrl}/user-pictures/${profileId}`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    const body = await response.json();

    return body;
  } catch (error) {
    return require('assets/images/boid-primary.png');
    //return error;
  }
}

async function getMyUserImage() {
  return getUserImage('me');
}

export default {
  getUserProfile,
  getMyUserProfile,
  getUserImage,
  getMyUserImage,
};
