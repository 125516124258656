interface UserProfileModel {
  /**
   * Unique user ID
   */
  id: string;
  /**
   * Unique Netlight ID
   */
  netlightId: string;
  /**
   * First name of the user
   */
  firstName: string;
  /**
   * Last name of the user
   */
  lastName: string;
  /**
   * Netlight office location the user is working at
   */
  office?: Offices;
  /**
   * Netlight employee role in the company
   */
  role?: string;
  /**
   * Netlight email address of the user
   */

  email: string;
  /**
   * Link to the user's profile picture
   */
  profilePicture: string;
  /**
   * Slack ID of the user
   */
  slackUserId: string;
}

export enum Offices {
  Munich = 'Munich',
  Stockholm = 'Stockholm',
  Helsinki = 'Helsinki',
  Oslo = 'Oslo',
  Zurich = 'Zurich',
  Frankfurt = 'Frankfurt',
  Berlin = 'Berlin',
  Hamburg = 'Hamburg',
  Copenhagen = 'Copenhagen',
  Amsterdam = 'Amsterdam',
  Cologne = 'Cologne',
}

export default UserProfileModel;
