import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

// centered card-image svg on login screen

function LoginIllustration(props: SvgProps) {
  return (
    <Svg
      viewBox="0 0 409 252"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeMiterlimit="10"
      {...props}
    >
      <Path fill="none" d="M99.272 0h221.376v251.796H99.272z" />
      <Path
        d="M246.61 222.356a29.63 29.63 0 0 0 2.465-1.695l.425-.339c.621-.492 1.206-1 1.773-1.542 3.881-3.693 6.507-8.417 7.536-13.558l2.322-8.948 34.965-141.607c1.645-6.688.445-13.726-3.334-19.569-3.78-5.844-9.832-10.014-16.826-11.597l-57.978-13.066-40.656-9.1c-6.791-1.104-13.768.302-19.514 3.934-5.746 3.632-9.831 9.217-11.426 15.623l-37.429 151.64c-1.65 6.688-.453 13.728 3.328 19.572 3.78 5.845 9.835 10.015 16.832 11.593l97.021 21.862c2.064.456 4.175.695 6.294.711 5.023-.016 9.941-1.372 14.202-3.914Z"
        fill="#f9f9fa"
        fillRule="nonzero"
      />
      <Path
        d="M169.189 46.633c.185 1.428.931 2.693 2.36 3.635l2.747-1.581c-.891-.523-1.491-1.419-1.591-2.18-.272-2.095 1.426-3.552 3.29-5.223 2.297-2.06 4.855-4.344 4.335-8.343-.533-4.093-4.488-6.96-10.303-6.272-4.529.536-7.808 2.857-9.712 6.079l2.718 2.288c1.46-2.54 3.496-4.376 7.071-4.799 3.766-.446 5.891 1.381 6.201 3.761.316 2.427-1.465 3.991-3.377 5.667-1.988 1.831-4.155 3.779-3.739 6.968Zm1.607 10.491c.186 1.428 1.532 2.477 2.962 2.307a2.626 2.626 0 0 0 2.281-2.928c-.186-1.428-1.532-2.477-2.962-2.307a2.626 2.626 0 0 0-2.281 2.928ZM228.934 196.421c-.555.927-.694 1.998-.265 3.173l2.409.248c-.297-.695-.243-1.484.053-1.979.814-1.359 2.528-1.49 4.442-1.676 2.36-.231 4.984-.481 6.537-3.075 1.591-2.657.528-6.062-3.329-8.162-3.004-1.636-6.083-1.674-8.748-.586l.582 2.529c2.076-.876 4.173-1.087 6.544.205 2.498 1.36 2.935 3.359 2.01 4.904-.943 1.575-2.757 1.734-4.703 1.903-2.065.227-4.293.447-5.532 2.516Zm-3.93 6.912a1.9 1.9 0 0 0 .722 2.645c.948.517 2.201.175 2.756-.751a1.9 1.9 0 0 0-.722-2.646c-.948-.516-2.201-.175-2.756.752Z"
        fill="#5c5bd0"
        fillRule="nonzero"
      />
      <Path
        d="m175.51 96.292-17.017-4.556 5.859-7.215 7.534 3.651.537 1.201.032.076 3.055 6.843ZM204.821 104.136l-10.891-2.916 1.172-1.794 9.719 4.71Z"
        fill="#4a49cb"
        fillRule="nonzero"
      />
      <Path
        d="m172.454 89.448-.072-.038.041-.037.031.075ZM192.282 98.06l-19.217-9.316.009-.004 7.809-7.592 11.395 16.904.004.008Z"
        fill="#4a49cb"
        fillOpacity="0.7"
        fillRule="nonzero"
      />
      <Path
        d="m184.3 115.985-8.79-19.693-3.055-6.843-.032-.076-.537-1.201 1.179.572 19.217 9.316-.004-.008 2.826 1.371-.002.003-1.172 1.794-9.63 14.765Z"
        fill="#4a49cb"
        fillOpacity="0.9"
        fillRule="nonzero"
      />
      <Path
        d="m192.282 98.06-19.217-9.316-1.179-.572-.004-.009 1.192.577 19.204 9.312.004.008Z"
        fill="#4a49cb"
        fillOpacity="0.8"
        fillRule="nonzero"
      />
      <Path
        d="M3.729 57.573c28.523 27.567 46.501 31.031 58 28.198C76.004 82.243 79.273 69.239 95.12 64.72c17.98-5.13 37.72 4.795 52.866 15.405"
        fill="none"
        fillRule="nonzero"
        stroke="#a6a2cd"
        strokeWidth="1"
        strokeDasharray="10"
      />
      <Path
        d="m221.626 120.679-4.048-3.662 4.604-5.431 4.024 5.833-4.58 3.26ZM231.378 129.507l3.264.637.262-.127 4.923 7.135-8.449-7.645Z"
        fill="#4a49cb"
        fillRule="nonzero"
      />
      <Path
        d="m234.642 130.144-7.32-11.349 21.384 4.557-13.802 6.665-.262.127Z"
        fill="#7f7ed9"
        fillRule="nonzero"
      />
      <Path
        d="m234.642 130.144-3.264-.637-17.371-3.399 7.619-5.429 4.58-3.26.156-.112.96 1.488 7.32 11.349Z"
        fill="#5c5bd0"
        fillRule="nonzero"
      />
      <Path
        d="M405.05 161.833c-30.212-29.144-49.254-32.805-61.434-29.81-15.12 3.73-18.582 17.476-35.368 22.254-19.044 5.423-39.953-5.069-55.997-16.286"
        fill="none"
        fillRule="nonzero"
        stroke="#a6a2cd"
        strokeWidth="1"
        strokeDasharray="11"
      />
      <Path
        d="M218.684 93.618c.02-.164.051-.417.086-.729.496-3.939 3.683-26.659 9.831-27.376 1.415-.165 3.057.508 3.908 1.735 4.134 5.961-12.127 24.299-13.807 26.193M196.692 130.912c-.031.162-.079.412-.135.721-.768 3.9-5.515 26.37-11.7 26.7-1.424.076-3.015-.699-3.78-1.976-3.713-6.208 13.778-23.485 15.585-25.27"
        fill="#fc4a4a"
        fillRule="nonzero"
      />
      <Path
        d="m223.26 106.015 1.246-1.102c6.786-5.942 46.342-39.983 55.476-36.9 2.102.71 3.194 2.911 2.391 5.451-3.899 12.349-53.674 30.415-58.815 32.282M192.01 122.854c-.331.256-.841.653-1.469 1.142-7.994 6.145-54.549 41.3-64.568 37.085-2.305-.971-3.38-3.54-2.312-6.337 5.19-13.6 62.116-29.924 67.997-31.611M209.254 93.334l-.303-1.142c-1.626-6.196-10.88-42.105-8.875-46.599.461-1.034 1.395-.935 2.282.435 4.316 6.662 6.587 43.251 6.821 47.031M205.84 134.565l.226 1.159c1.207 6.288 8.036 42.718 5.735 47.072-.53 1.002-1.455.843-2.248-.582-3.86-6.926-3.676-43.583-3.657-47.369"
        fill="#ff7979"
        fillRule="nonzero"
      />
    </Svg>
  );
}

export default LoginIllustration;
