import { MaterialIcons } from '@expo/vector-icons';
import Config from 'config';
import React, { ReactElement, useState } from 'react';
import { Alert, Image, Linking, Pressable, StyleSheet } from 'react-native';

import COLORS from 'styles/colors';
import CONSTANTS from 'styles/constants';

import StyledButton, {
  ButtonColors,
  ButtonSize,
  ButtonTypes,
} from './StyledButton';
import StyledText from './StyledText';

export type SlackReachOutButtonProps = {
  userId: string;
  transparant?: boolean | undefined;
};

function SlackReachOutButton(props: SlackReachOutButtonProps): ReactElement {
  const { userId, transparant } = props;

  let ButtonType: ButtonTypes = ButtonTypes.FILLED;
  let ButtonColor: ButtonColors = ButtonColors.PRIMARY;
  let slackMargin: number = 10;
  let fontWeight: any = 'normal';
  let imagePath = require('assets/images/Slack-white.png');

  if (transparant) {
    ButtonType = ButtonTypes.TRANSPARENT;
    ButtonColor = ButtonColors.TRANSPARENT;
    slackMargin = 3;
    imagePath = require('assets/images/Slack-black.png');
  } else {
    fontWeight = 'bold';
  }

  const handlePress = async () => {
    const SlackURL = `slack://user?team=${Config.slackTeamId}&id=${userId}`;

    // Checking if the link is supported for links with custom URL scheme.
    const supported = await Linking.canOpenURL(SlackURL);

    if (supported) {
      // Opening the link with some app, if the URL scheme is "http" the web link should be opened
      // by some browser in the mobile
      await Linking.openURL(SlackURL);
    } else {
      Alert.alert(`Don't know how to open this URL: ${SlackURL}`);
    }
  };

  const styles = StyleSheet.create({
    slackImage: {
      width: 14,
      aspectRatio: 1,
      marginRight: slackMargin,
    },
  });

  return (
    <StyledButton
      onPress={handlePress}
      size={ButtonSize.SMALL}
      title="Reach out"
      type={ButtonType}
      color={ButtonColor}
      fontWeight={fontWeight}
      startIcon={<Image source={imagePath} style={[styles.slackImage]} />}
    ></StyledButton>
  );
}

export default SlackReachOutButton;
