import Config from 'config';

import ConnectionModel from 'models/ConnectionModel';

import AuthManager from 'services/auth/AuthManager';

const baseUrl = `${Config.apiBaseUrl}/connection`;

async function addConnection(idToAdd: string) {
  const accessToken = await AuthManager.getValidAccessToken();

  const response = await fetch(`${baseUrl}/connections`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      otherUserId: idToAdd,
    }),
  });

  const body = await response.json();
  return body;
}

async function getConnection(otherUserId: string): Promise<ConnectionModel> {
  const accessToken = await AuthManager.getValidAccessToken();
  try {
    const response = await fetch(`${baseUrl}/connections/${otherUserId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const body = await response.json();
    return body;
  } catch (e) {
    console.log('Error in getConnection: ' + e);

    let error: ConnectionModel = {
      note: '',
      otherUserId: '',
      sharedData: { connectedAtTime: '', id: '' },
      userId: '',
    };

    return error;
  }
}

async function getConnections(): Promise<ConnectionModel[]> {
  const accessToken = await AuthManager.getValidAccessToken();
  let connections = [];
  try {
    const response = await fetch(`${baseUrl}/connections`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    connections = await response.json();
    return connections;
  } catch (e) {
    console.log('Error in getConnections: ' + e);
    return connections;
  }
}

async function addNote(otherUserId: string, text: string) {
  const accessToken = await AuthManager.getValidAccessToken();
  const response = await fetch(`${baseUrl}/connections/${otherUserId}/note`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      note: text,
    }),
  });

  const body = await response;
  return body;
}

export default {
  addConnection,
  getConnection,
  getConnections,
  addNote,
};
