import React, { ReactElement, ReactNode } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import StyledText from 'components/StyledText';

import COLORS from 'styles/colors';

type SectionProps = {
  style?: StyleProp<ViewStyle>;
  title?: string;
  children: ReactNode;
};

function SectionContainer(props: SectionProps): ReactElement {
  const { title, children, style } = props;
  return (
    <View style={style}>
      <StyledText style={styles.sectionHeader}>{title}</StyledText>
      <View>{children}</View>
    </View>
  );
}

export default SectionContainer;

const styles = StyleSheet.create({
  sectionHeader: {
    color: COLORS.PRIMARY,
    fontSize: 24,
    fontWeight: '700',
    marginTop: 5,
    marginBottom: 15,
  },
});
