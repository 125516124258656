import * as React from 'react';
import Svg, {
  Defs,
  Ellipse,
  LinearGradient,
  Path,
  Stop,
  SvgProps,
} from 'react-native-svg';

function IntroSlider3(props: SvgProps) {
  return (
    <Svg width={217} height={218} fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.5 11.5c23.719 3.091 42.239 15.56 50.508 7.896C202.643 11.392 236.394 147.898 200 186c-36.932 38.667-116.135 35.017-154.5 20.5-39.367-14.897-20.439-31.542-14.823-40.647 5.616-9.105-4.492-24.95-11.073-43.647-6.482-18.419-9.926-41.31-8.13-69.99.9-14.376 9.39-22.636 18.362-27.901 13.55-7.95 32.21-8.568 49.673-10.33 15.447-1.556 56.412-3.474 63.991-2.485Z"
        fill="url(#a)"
      />
      <Path
        d="M87.807 49C86.513 5.678 129.162 0 172.162 0c49.855 0 10.167 57 5.656 84.5-4.512 27.5 27.809 48.3 35 87.5 0 10.512 10.988 38.5-18.512 44.5s-53.999-27-72-32c-18-5-87.144 18-96.5-17.5-13.579-51.526 25.694-44.5 41.5-63 15.807-18.5 20.86-42.94 20.5-55Z"
        fill="#F3F3F9"
      />
      <Path
        d="M208 101.5c0 31.882-28.481 63.5-57.2 63.5S104 133.382 104 101.5 127.281 38 156 38s52 31.618 52 63.5Z"
        fill="#E5E4F8"
      />
      <Ellipse cx={108.5} cy={91.5} rx={13.5} ry={17.5} fill="#706FE8" />
      <Path
        d="M21 115c7.29 0 89-6 89-6l-1.703-35S24.524 69 21 69v46Z"
        fill="#706FE8"
      />
      <Path
        d="M38.99 95.123c0 13.976-8.726 24.622-18.745 24.622-10.02 0-18.745-10.646-18.745-24.623 0-6.288.85-9.943 3.668-14.19 1.77-2.667 4.192-5.307 6.88-7.267 2.705-1.972 5.552-3.165 8.197-3.165 5.239 0 10.246 3.505 13.797 8.51 3.115 4.392 4.948 9.625 4.948 16.112Z"
        fill="#fff"
        stroke="#4A49CB"
        strokeWidth={3}
      />
      <Ellipse cx={13.061} cy={95.122} rx={7.837} ry={9.143} fill="#4A49CB" />
      <Ellipse cx={116.5} cy={100.5} rx={13.5} ry={17.5} fill="#9796F5" />
      <Path
        d="M36.5 132.5c7.209 0 81.5-14.5 81.5-14.5l-1.684-35S38.984 81 35.5 81c0 11.2 1 42.833 1 51.5Z"
        fill="#9796F5"
      />
      <Path
        d="M54.663 106.878c0 13.976-8.725 24.622-18.744 24.622-10.02 0-18.745-10.646-18.745-24.622 0-13.977 8.725-24.623 18.745-24.623 10.019 0 18.744 10.646 18.744 24.623Z"
        fill="#fff"
        stroke="#4A49CB"
        strokeWidth={3}
      />
      <Ellipse cx={28.735} cy={106.877} rx={7.837} ry={9.143} fill="#4A49CB" />
      <Path
        d="M110.5 60c6.8 11.2 31.833 14 43.5 14 15.5 1.222 52.5 25.5 53.5 21 .8-3.6-1.667-11.5-3-15-3-11-11.5-23-21-31.5s-23.5-10.5-34-10-20 7-28 7.5c-6.4.4-12.667-2.5-15-4-1.5 1.333-2.8 6.8 4 18Z"
        fill="#4A49CB"
      />
      <Path
        d="M167.761 91.748c4.319-2.794 12.947-5.718 12.903 4.928-.044 10.646-9.307 17.64-13.934 19.807"
        stroke="#4A49CB"
        strokeOpacity={0.12}
        strokeWidth={3}
      />
      <Defs>
        <LinearGradient
          id="a"
          x1={117.197}
          y1={220.616}
          x2={117.197}
          y2={1.002}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#FC4A4A" />
          <Stop offset={0.588} stopColor="#303F9F" />
          <Stop offset={1} stopColor="#4A49CB" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export default IntroSlider3;
