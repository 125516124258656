import { createStackNavigator } from '@react-navigation/stack';
import { ReactElement } from 'react';

import ScanScreen from './ScanScreen';
import SuccessfulScanScreen from './SuccessfulScanScreen';

export type ScanStackPropList = {
  ScannerScreen: undefined;
  SuccessfulScanScreen: {
    id: string;
  };
};

const Stack = createStackNavigator<ScanStackPropList>();

function ScanNavigation(): ReactElement {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        animationEnabled: true,
      }}
      initialRouteName="ScannerScreen"
    >
      <Stack.Screen name="ScannerScreen" component={ScanScreen} />
      <Stack.Screen
        name="SuccessfulScanScreen"
        component={SuccessfulScanScreen}
      />
    </Stack.Navigator>
  );
}

export default ScanNavigation;
