import { MaterialIcons } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import { Modal, SafeAreaView, StyleSheet, View } from 'react-native';

import StyledButton, {
  ButtonColors,
  ButtonSize,
  ButtonTypes,
} from 'components/StyledButton';
import StyledText from 'components/StyledText';
import SwitchButton, { SwitchButtonItem } from 'components/SwitchButton';

import { ExtendedBoidCardModel } from 'models/BoidCardModel';

import COLORS from 'styles/colors';
import CONSTANTS from 'styles/constants';

import { FilterCategoriesProps, FilterOption } from './FilterOptionsModel';

export type FilterModalProps = {
  defaultSort: SwitchButtonItem;
  filterCategories: FilterCategoriesProps;
  modalVisible: boolean;
  filterSortingResults: ExtendedBoidCardModel[];
  setModalVisible: () => void;
  setFilters: (filters: SelectedFilters[], sort: SwitchButtonItem) => void;
};

export type SelectedFilters = {
  label: string;
  selectedOptions: FilterOption[];
};

function FilterModal1(props: FilterModalProps) {
  const {
    defaultSort,
    filterCategories,
    modalVisible,
    filterSortingResults,
    setModalVisible,
    setFilters,
  } = props;
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters[]>([]);
  const [selectedSort, setSelectedSort] =
    useState<SwitchButtonItem>(defaultSort);
  const [newFiltersSelected, setNewFiltersSelected] = useState(false);

  useEffect(() => {
    if (modalVisible) setNewFiltersSelected(false);
  }, [modalVisible]);

  const toggleSelectedFilters = (category: string, option: FilterOption) => {
    setNewFiltersSelected(true);
    let filtersForCategory = selectedFilters.filter(
      (f) => f.label === category,
    )[0];
    if (filtersForCategory) {
      if (filtersForCategory.selectedOptions.includes(option)) {
        filtersForCategory.selectedOptions =
          filtersForCategory.selectedOptions.filter((o) => {
            return o.label !== option.label && option;
          });
      } else {
        filtersForCategory.selectedOptions.push(option);
      }
      setSelectedFilters(
        selectedFilters.map((filter) =>
          filter.label === category ? filtersForCategory : filter,
        ),
      );
      setFilters([...selectedFilters], selectedSort);
    } else {
      filtersForCategory = {
        label: category,
        selectedOptions: [option],
      };
      setSelectedFilters([...selectedFilters, filtersForCategory]);
      setFilters([...selectedFilters, filtersForCategory], selectedSort);
    }
  };

  const getSelectedFilterCategorysForCategory = (
    category: string,
  ): SelectedFilters => {
    return selectedFilters.filter((f) => f.label === category)[0];
  };

  return (
    <Modal
      animationType="slide"
      transparent={false}
      visible={modalVisible}
      style={{ flex: 1 }}
      onRequestClose={() => {
        setModalVisible();
      }}
    >
      <SafeAreaView
        style={{ margin: 20 }}
        //style={[styles.dropdown, { top: 70, right: 20, padding: 20 }]}
      >
        <View style={{ marginVertical: 15 }}>
          <StyledButton
            title=""
            style={{ marginBottom: 20, alignSelf: 'flex-start' }}
            startIcon={
              <MaterialIcons color={COLORS.WHITE} name="arrow-back" size={16} />
            }
            onPress={() => {
              setModalVisible();
            }}
          />
          <StyledText style={{ fontWeight: '600', fontSize: 18 }}>
            Sort by
          </StyledText>
          <SwitchButton
            size={ButtonSize.SMALL}
            defaultSortProp={defaultSort}
            buttonItems={filterCategories.sortOptions as SwitchButtonItem[]}
            onSwitch={(sortProp) => {
              setSelectedSort(sortProp);
            }}
          />
        </View>
        <View style={{ marginVertical: 15 }}>
          <StyledText style={{ fontWeight: '600', fontSize: 18 }}>
            Filter
          </StyledText>
          {filterCategories.filters.map((filter, index) => (
            <View key={index}>
              <StyledText style={{ marginTop: 5 }}>
                {filter.category}
              </StyledText>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  marginTop: 15,
                }}
              >
                {filter.options &&
                  filter.options.map((option: FilterOption, i: number) => (
                    <StyledButton
                      key={i}
                      size={ButtonSize.SMALL}
                      type={
                        getSelectedFilterCategorysForCategory(
                          filter.category,
                        ) &&
                        getSelectedFilterCategorysForCategory(
                          filter.category,
                        ).selectedOptions.includes(option)
                          ? ButtonTypes.FILLED
                          : ButtonTypes.OUTLINED
                      }
                      color={
                        getSelectedFilterCategorysForCategory(
                          filter.category,
                        ) &&
                        getSelectedFilterCategorysForCategory(
                          filter.category,
                        ).selectedOptions.includes(option)
                          ? ButtonColors.PRIMARY
                          : ButtonColors.GRAY
                      }
                      style={
                        (styles.filterButton,
                        { marginRight: 10, marginBottom: 10 })
                      }
                      startIcon={
                        getSelectedFilterCategorysForCategory(
                          filter.category,
                        ) &&
                        getSelectedFilterCategorysForCategory(
                          filter.category,
                        ).selectedOptions.includes(option) ? (
                          <MaterialIcons
                            name="done"
                            size={14}
                            color={COLORS.WHITE}
                            style={{ paddingHorizontal: 3 }}
                          />
                        ) : undefined
                      }
                      title={option.label}
                      onPress={() =>
                        toggleSelectedFilters(filter.category, option)
                      }
                    />
                  ))}
              </View>
            </View>
          ))}
        </View>

        <View style={styles.modalActionsContainer}>
          <StyledButton
            style={{ width: 120, alignSelf: 'stretch', flex: 2 }}
            type={ButtonTypes.TEXT}
            title="Clear"
            onPress={() => {
              setFilters([], defaultSort);
              setModalVisible();
              setSelectedFilters([]);
            }}
          />
          <StyledButton
            style={{ width: 120, flex: 2 }}
            type={ButtonTypes.FILLED}
            title={`Show ${filterSortingResults.length} results`}
            disabled={!newFiltersSelected}
            onPress={() => {
              setModalVisible();
            }}
          />
        </View>
      </SafeAreaView>
    </Modal>
  );
}

export default FilterModal1;

const styles = StyleSheet.create({
  dropdown: {
    position: 'absolute',
    backgroundColor: COLORS.WHITE,
    width: '80%',
    borderRadius: CONSTANTS.BORDER_RADIUS,
  },
  filterButton: {
    marginVertical: 4,
  },
  activeFilterButton: {
    backgroundColor: COLORS.SECONDARY,
  },
  modalActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
});
