import { createStackNavigator } from '@react-navigation/stack';
import { ReactElement } from 'react';

import { ExtendedBoidCardModel } from 'models/BoidCardModel';

import CardDetailsScreen from './CardDetailsScreen';
import CollectionScreen from './CollectionScreen';

export type CollectionStackPropList = {
  CollectionScreen: undefined;
  CardDetailsScreen: {
    boidCard: ExtendedBoidCardModel;
  };
};

const Stack = createStackNavigator<CollectionStackPropList>();

function CollectionNavigation(): ReactElement {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        animationEnabled: true,
      }}
      initialRouteName="CollectionScreen"
    >
      <Stack.Screen name="CollectionScreen" component={CollectionScreen} />
      <Stack.Screen name="CardDetailsScreen" component={CardDetailsScreen} />
    </Stack.Navigator>
  );
}

export default CollectionNavigation;
