function DateChanger(date: string): string {
  let array: string[] = date.split('-', 2);

  if (array[0] === '1970' && array[1] === '01') {
    return 'Spring 2022';
  }
  switch (array[1]) {
    case '01':
      return 'January ' + array[0];
    case '02':
      return 'February ' + array[0];
    case '03':
      return 'March ' + array[0];
    case '04':
      return 'April ' + array[0];
    case '05':
      return 'May ' + array[0];
    case '06':
      return 'June ' + array[0];
    case '07':
      return 'July ' + array[0];
    case '08':
      return 'August ' + array[0];
    case '09':
      return 'September ' + array[0];
    case '10':
      return 'October ' + array[0];
    case '11':
      return 'November ' + array[0];
    case '12':
      return 'December ' + array[0];
    default:
      return 'Month ' + array[0];
  }
}
export default DateChanger;
