import Config from 'config';
import { ReactElement, useMemo } from 'react';
import { View } from 'react-native';
import NativeQrCode from 'react-native-qrcode-svg';

import LoadingIndicator from './LoadingIndicator';

export type QrCodeProps = {
  boidCode?: string;
  size?: number;
};

function BoidQrCode(props: QrCodeProps): ReactElement {
  const { boidCode, size = 190 } = props;

  const expandedBoidCode = useMemo(() => {
    return `${Config.appSchema}://${boidCode}`;
  }, [boidCode]);

  return (
    <View style={{ borderRadius: 6, overflow: 'hidden' }}>
      {boidCode ? (
        <NativeQrCode
          value={expandedBoidCode}
          logo={require('assets/images/boid-primary.png')}
          logoSize={35}
          logoBorderRadius={4}
          size={size}
          backgroundColor="transparent"
          logoBackgroundColor="white"
          color="#4A49CB"
        />
      ) : (
        <LoadingIndicator />
      )}
    </View>
  );
}

export default BoidQrCode;
