import React, { ReactElement, useEffect, useState } from 'react';
import {
  Pressable,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import COLORS from 'styles/colors';
import CONSTANTS from 'styles/constants';

import { ButtonSize } from './StyledButton';
import StyledText from './StyledText';

export type SwitchButtonProps = {
  defaultSortProp: SwitchButtonItem;
  buttonItems: SwitchButtonItem[];
  size?: ButtonSize;
  onSwitch: (val: SwitchButtonItem) => void;
};

export type SwitchButtonItem = {
  label: string;
  value: string;
};

function SwitchButton(props: SwitchButtonProps): ReactElement {
  const {
    defaultSortProp,
    buttonItems,
    onSwitch,
    size = ButtonSize.MEDIUM,
  } = props;
  const [currentSelected, setCurrentSelected] = useState(defaultSortProp);

  const onSwitchHandler = (item: SwitchButtonItem) => {
    setCurrentSelected(item);
    onSwitch(item);
  };

  return (
    <View style={styles.switchButtonContainer}>
      {buttonItems.map((item, index) => (
        <Pressable
          key={index}
          style={[
            styles.switchButtonBase,
            item.value === currentSelected.value
              ? styles.switchButtonActive
              : undefined,
            size === ButtonSize.MEDIUM
              ? { padding: 12 }
              : size === ButtonSize.SMALL
              ? { padding: 8 }
              : { padding: 16 },
          ]}
          onPress={() => onSwitchHandler(item)}
          disabled={false}
        >
          <StyledText
            style={[
              item.value === currentSelected.value
                ? styles.switchButtonTextActive
                : styles.switchButtonText,
              size === ButtonSize.MEDIUM
                ? { fontSize: 16 }
                : size === ButtonSize.SMALL
                ? { fontSize: 14 }
                : { fontSize: 18 },
            ]}
          >
            {item.label}
          </StyledText>
        </Pressable>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  switchButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 12,
    borderColor: COLORS.PRIMARY,
    borderWidth: 2,
    borderRadius: CONSTANTS.BORDER_RADIUS_ROUND,
    alignSelf: 'flex-start',
    padding: 2,
  },
  switchButtonBase: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  switchButtonActive: {
    backgroundColor: COLORS.PRIMARY,
    borderColor: COLORS.PRIMARY,
    borderWidth: 2,
    borderRadius: CONSTANTS.BORDER_RADIUS_ROUND,
  },
  switchButtonTextActive: {
    color: '#ffffff',
  },
  switchButtonText: {
    color: COLORS.PRIMARY,
  },
});

export default SwitchButton;
