import React, { ReactElement } from 'react';
import { Image, ImageStyle, StyleProp, StyleSheet, View } from 'react-native';

export type OfficeProps = {
  office: string;
  style?: StyleProp<ImageStyle>;
};

function Office(props: OfficeProps): ReactElement {
  const { office } = props;

  const getOfficeImage = (currentOffice: string) => {
    switch (currentOffice) {
      case 'Amsterdam':
        return (
          <Image
            source={require('assets/images/offices/Amsterdam.png')}
            style={styles.officeImage}
          />
        );
      case 'Cologne':
        return (
          <Image
            source={require('assets/images/offices/Cologne.png')}
            style={styles.officeImage}
          />
        );
      case 'Berlin':
        return (
          <Image
            source={require('assets/images/offices/Berlin.png')}
            style={styles.officeImage}
          />
        );
      case 'Copenhagen':
        return (
          <Image
            source={require('assets/images/offices/Copenhagen.png')}
            style={styles.officeImage}
          />
        );
      case 'Frankfurt':
        return (
          <Image
            source={require('assets/images/offices/Frankfurt.png')}
            style={styles.officeImage}
          />
        );
      case 'Hamburg':
        return (
          <Image
            source={require('assets/images/offices/Hamburg.png')}
            style={styles.officeImage}
          />
        );
      case 'Helsinki':
        return (
          <Image
            source={require('assets/images/offices/Helsinki.png')}
            style={styles.officeImage}
          />
        );
      case 'Munich':
        return (
          <Image
            source={require('assets/images/offices/Munich.png')}
            style={styles.officeImage}
          />
        );
      case 'Oslo':
        return (
          <Image
            source={require('assets/images/offices/Oslo.png')}
            style={styles.officeImage}
          />
        );
      case 'Stockholm':
        return (
          <Image
            source={require('assets/images/offices/Stockholm.png')}
            style={styles.officeImage}
          />
        );
      case 'Zurich':
        return (
          <Image
            source={require('assets/images/offices/Zurich.png')}
            style={styles.officeImage}
          />
        );
      default:
        return (
          <Image
            source={require('assets/images/flags/NA.png')}
            style={styles.officeImage}
          />
        );
    }
  };

  return (
    <View style={styles.officeImageContainer}>{getOfficeImage(office)}</View>
  );
}

const styles = StyleSheet.create({
  officeImageContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 7,
  },
  officeImage: {
    width: 25,
    height: 25,
    resizeMode: 'contain',
  },
});
export default Office;
