import { MaterialIcons } from '@expo/vector-icons';
import { StackScreenProps } from '@react-navigation/stack';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FlatList, Pressable, StyleSheet, Text, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { RefreshControl } from 'react-native-web-refresh-control';

import LoadingIndicator from 'components/LoadingIndicator';
import PrimaryGradient from 'components/PrimaryGradient';
import SearchBar from 'components/SearchBar';
import StyledText from 'components/StyledText';
import { SwitchButtonItem } from 'components/SwitchButton';

import { ExtendedBoidCardModel } from 'models/BoidCardModel';

import BoidCardService from 'services/boid-card/BoidCardService';
import ConnectionService from 'services/connection/ConnectionService';

import COLORS from 'styles/colors';

import CardItem from './CardItem';
import { CollectionStackPropList } from './CollectionNavigation';
import FilterModal, { SelectedFilters } from './FilterModal';
import { FilterCategories, FilterScheme } from './FilterOptionsModel';
import ListItem from './ListItem';

function CollectionScreen(): ReactElement {
  const [displayMode, setDisplayMode] = useState<DisplayMode>('collection');

  const [searchText, setSearchText] = useState<string>('');

  const [data, setData] = useState<ExtendedBoidCardModel[]>([]);
  const [collectedBoidCardIds, setCollectedBoidCardIds] = useState<string[]>(
    [],
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [filters, setFilters] = useState<SelectedFilters[]>([]);
  const [sort, setsort] = useState<SwitchButtonItem>(
    FilterScheme.sortOptions[0],
  );
  const [filterActive, setFilterActive] = useState(false);
  const [inSearchingMode, setInSearchingMode] = useState(false);

  useEffect(() => {
    // Initial load of all collected cards
    setLoading(true);
    BoidCardService.getMyCollectedBoidCards()
      .then((val) => {
        const sorted = val.sort((e1, e2) =>
          e1.firstName > e2.firstName ? 1 : -1,
        );
        setData(sorted);
        const list = [];
        if (val) {
          for (var i = 0; i < val.length; i++) {
            list.push(val[i].id);
          }
          setCollectedBoidCardIds(list);
        }
      })
      .catch((error) => {
        setData([]), console.log(error);
      })
      .finally(() => setLoading(false));
  }, []);

  const filteredData = useMemo<ExtendedBoidCardModel[]>(() => {
    if (data && data.length > 0) {
      setLoading(true);
      // Search
      const lowerCaseSearchText = searchText.toLowerCase();
      let searched = data.filter((card) => {
        const displayName = `${card.firstName} ${card.lastName}`.toLowerCase();
        const nameMatched = displayName.includes(lowerCaseSearchText);
        // Search the shortened name
        let shortenedNameMatched = false;
        if (card.firstName && card.lastName) {
          const shortenedName = (
            card.firstName.substring(0, 2) + card.lastName.substring(0, 2)
          ).toLowerCase();
          shortenedNameMatched = shortenedName.includes(lowerCaseSearchText);
        }
        const emailMatched = card.email.includes(lowerCaseSearchText);

        return nameMatched || shortenedNameMatched || emailMatched;
      });

      setFilterActive(filters.length > 0);

      if (filters.length > 0 && inSearchingMode) {
        setDisplayMode('directory');
      } else if (filters.length > 0 && !inSearchingMode) {
        setDisplayMode('directory');
      } else if (filters.length === 0 && inSearchingMode) {
        setDisplayMode('directory');
      } else {
        setDisplayMode('collection');
      }
      filters.forEach((filter) => {
        if (
          filter.label === FilterCategories.LOCATION &&
          filter.selectedOptions.length > 0
        ) {
          searched = searched.filter(
            (boid) =>
              filter.selectedOptions.filter(
                (option) => option.label === (boid.office as string),
              ).length === 1,
          );
        }
        // TODO: implement here filters based on other boid properties
        //e.g.: if (filter.label === FilterCategories.POSITION) searched = ...;
      });

      searched.sort((a, b) => {
        const aFirst = a[sort.value as keyof ExtendedBoidCardModel]
          ?.toString()
          .toLowerCase();
        const bFirst = b[sort.value as keyof ExtendedBoidCardModel]
          ?.toString()
          .toLowerCase();
        if (aFirst && bFirst) {
          if (aFirst < bFirst) return -1;
          if (aFirst > bFirst) return 1;
        }
        return 0;
      });

      setLoading(false);

      return searched;
    }
    return data;
  }, [data, searchText, filters, sort]);

  const handleFilter = (
    selectedFilters: SelectedFilters[],
    sort: SwitchButtonItem,
  ) => {
    setFilters(selectedFilters);
    setsort(sort);
  };

  const handleSearchInit = useCallback(() => {
    setInSearchingMode(true);

    if (displayMode === 'directory') {
      return;
    }
  }, [displayMode]);

  const handleSearchCancel = useCallback(() => {
    setInSearchingMode(false);

    if (displayMode === 'collection') {
      return;
    }
    setLoading(true);
    setSearchText('');
    setData([]);

    // Search canceled, so load all collected cards again
    BoidCardService.getMyCollectedBoidCards()
      .then(setData)
      .catch(() => setData([]))
      .finally(() => setLoading(false));
  }, [displayMode]);

  const handleRefresh = useCallback(() => {
    setRefreshing(true);

    const fetchFunction = BoidCardService.getMyCollectedBoidCards();
    fetchFunction
      .then(setData)
      .catch(() => setData([]))
      .finally(() => setRefreshing(false));
  }, [displayMode]);

  return (
    <SafeAreaView style={styles.page}>
      <PrimaryGradient />
      <View style={styles.searchContainer}>
        <SearchBar
          value={searchText}
          onChangeText={setSearchText}
          onFocusSearchbar={handleSearchInit}
          cancelEnabled={inSearchingMode}
          onCancel={handleSearchCancel}
          onClear={() => setSearchText('')}
          placeholder={`Search all Boids...`}
          filterButton={
            <Pressable onPress={() => setModalVisible(!modalVisible)}>
              <MaterialIcons
                name="filter-alt"
                size={25}
                color={filterActive ? COLORS.PRIMARY : COLORS.PRIMARY + '80'}
                style={styles.filterIcon}
              />
            </Pressable>
          }
        />
      </View>

      {displayMode === 'collection' && (
        <Text style={styles.infoText}>
          Showing {filteredData.length} collected boids
        </Text>
      )}

      {displayMode === 'directory' && (
        <Text style={styles.infoText}>
          Showing {filteredData.length} of {data.length} total boids
        </Text>
      )}

      <FlatList
        data={padData(
          filteredData,
          displayMode === 'collection' ? noColumns : 1,
        )}
        style={styles.scrollContainer}
        contentContainerStyle={styles.contentWrapper}
        renderItem={({ item }) =>
          collectionListItemRenderer(item, collectedBoidCardIds, displayMode)
        }
        keyExtractor={(boidCard) => boidCard.id}
        key={displayMode === 'collection' ? 'grid' : 'list'} // Enable changing numColumns
        numColumns={displayMode === 'collection' ? noColumns : 1}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />
        }
        ListEmptyComponent={() => (
          // change component to also fit the scenario where filtering does not yeld any results
          <EmptyList searchActive={!!searchText} loading={loading} />
        )}
      />

      <FilterModal
        defaultSort={sort}
        filterCategories={FilterScheme}
        modalVisible={modalVisible}
        filterSortingResults={filteredData}
        setModalVisible={() => setModalVisible(!modalVisible)}
        setFilters={(offices, sort) => {
          handleFilter(offices, sort);
        }}
      />
    </SafeAreaView>
  );
}

type DisplayMode = 'collection' | 'directory';

function padData(items: Array<unknown>, colCount: number) {
  const lastRowItemCount = items.length % colCount;
  const missingItemsForFullGrid = (colCount - lastRowItemCount) % colCount;
  return [
    ...items,
    ...new Array(missingItemsForFullGrid).fill({ id: 'filler' }),
  ];
}

function collectionListItemRenderer(
  card: ExtendedBoidCardModel,
  collectedBoidCardIds: string[],
  displayMode: DisplayMode,
): ReactElement {
  if (card.id === 'filler') {
    return <View style={styles.itemWrapper} />;
  }

  return (
    <View style={styles.itemWrapper}>
      {displayMode === 'directory' ? (
        <ListItem
          isCollected={collectedBoidCardIds.includes(card.id)}
          boidCard={card}
        />
      ) : (
        <CardItem boidCard={card} />
      )}
    </View>
  );
}

type EmptyListProps = {
  loading: boolean;
  searchActive: boolean;
};
function EmptyList(props: EmptyListProps): ReactElement {
  const { loading, searchActive } = props;

  return (
    <View style={styles.emptyListContainer}>
      {loading ? (
        <LoadingIndicator />
      ) : searchActive ? (
        <StyledText style={styles.emptyListText}>No Boids found...</StyledText>
      ) : (
        <StyledText style={styles.emptyListText}>
          Oh no!{'\n'}
          You don't have any Boid in your collection yet :({'\n\n'}
          Use the scanner to scan BoidCodes when you meet Netlighters in person
        </StyledText>
      )}
    </View>
  );
}

export default CollectionScreen;

const itemGap = 8;
const noColumns = 3;
const styles = StyleSheet.create({
  page: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'white',
  },
  searchContainer: {
    width: '100%',
    flexDirection: 'row',
    padding: 24,
    paddingBottom: 10,
    alignItems: 'center',
  },
  scrollContainer: {
    width: '100%',
  },
  contentWrapper: {
    flex: 1,
    paddingHorizontal: 24,
    margin: -0.5 * itemGap,
  },
  itemWrapper: {
    flex: 1,
    margin: 0.5 * itemGap,
  },
  emptyListContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyListText: {
    textAlign: 'center',
  },
  filterIcon: {
    paddingHorizontal: 3,
  },
  infoText: {
    color: COLORS.LIGHT_GRAY,
    paddingBottom: 10,
  },
});
