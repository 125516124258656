import { LinearGradient } from 'expo-linear-gradient';
import { ReactElement } from 'react';
import { ImageBackground, StyleSheet } from 'react-native';

function PrimaryGradient(): ReactElement {
  return (
    <ImageBackground
      source={require('assets/images/Background.svg')}
      style={StyleSheet.absoluteFill}
    />
  );
}

export default PrimaryGradient;
