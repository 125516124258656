import AsyncStorage from '@react-native-async-storage/async-storage';
import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

export type LaunchContextType = {
  isLoading: boolean;
  isFirstLaunch: boolean;
  markAsLaunched: () => void;
};

const defaultValue: LaunchContextType = {
  isLoading: true,
  isFirstLaunch: true,
  markAsLaunched: () => {},
};

const LaunchContext = React.createContext<LaunchContextType>(defaultValue);

export function LaunchContextProvider(
  props: PropsWithChildren<unknown>,
): ReactElement {
  const { children } = props;

  const [isLoading, setLoading] = useState<boolean>(true);
  const [isFirstLaunch, setFirstLaunch] = useState<boolean>(false);

  const markAsLaunched = useCallback(() => {
    setFirstLaunch(false);
    AsyncStorage.setItem('hasLaunched', JSON.stringify(true));
  }, []);

  useEffect(() => {
    AsyncStorage.getItem('hasLaunched')
      .then((rawValue) => {
        if (rawValue === null) {
          return false;
        }

        return JSON.parse(rawValue);
      })
      .then((hasLaunched) => {
        setFirstLaunch(!hasLaunched);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const launchContext = useMemo(
    () => ({
      isLoading,
      isFirstLaunch,
      markAsLaunched,
    }),
    [isLoading, isFirstLaunch, markAsLaunched],
  );

  return (
    <LaunchContext.Provider value={launchContext}>
      {children}
    </LaunchContext.Provider>
  );
}

export default LaunchContext;
