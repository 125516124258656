import React, { ReactElement, useContext, useState } from 'react';
import {
  Dimensions,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';

import StyledButton, { ButtonColors } from 'components/StyledButton';

import LaunchContext from 'services/launch/LaunchContext';

import COLORS from 'styles/colors';

import IntroCard from './IntroCard';

function IntroScreen(): ReactElement {
  const { width } = Dimensions.get('window');
  const { markAsLaunched } = useContext(LaunchContext);

  const [sliderPageIndex, setSliderPageIndex] = useState<number>(0);

  const changeSliderPage = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const { x } = event.nativeEvent.contentOffset;
    const indexOfNextScreen = Math.floor(x / width);
    setSliderPageIndex((currentIndex) => {
      return indexOfNextScreen !== currentIndex
        ? indexOfNextScreen
        : currentIndex;
    });
  };

  return (
    <View style={styles.page}>
      <ScrollView
        horizontal
        scrollEventThrottle={16}
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        style={styles.scrollContainer}
        onScroll={changeSliderPage}
        bounces={false}
      >
        {introSlides.map((info) => (
          <IntroCard
            key={info.id}
            id={info.id}
            title={info.title}
            description={info.description}
          />
        ))}
      </ScrollView>

      <StyledButton
        fullWidth
        onPress={markAsLaunched}
        title={
          sliderPageIndex === introSlides.length - 1
            ? "Let's go!"
            : 'Skip tutorial'
        }
        color={ButtonColors.CONFIRM}
        style={{ margin: 20, marginBottom: 85 }}
      />
    </View>
  );
}

export default IntroScreen;

const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: COLORS.PRIMARY,
  },
  scrollContainer: {
    flex: 1,
  },
  paginationWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
});

const introSlides = [
  {
    id: '1',
    title: 'Scan and get scanned',
    description:
      'In BoidBook, you can connect with other Netlighters by scanning each others’ BoidCodes.',
  },
  {
    id: '2',
    title: 'Who have you met?',
    description:
      'You will find all boids you have scanned in your collection - a great way to keep track of who you’ve met.',
  },
  {
    id: '3',
    title: 'Explore Netlight',
    description:
      'Explore colleagues from other offices and find out who you would like to meet next!',
  },
];
