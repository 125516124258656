import { NavigationProp, useNavigation } from '@react-navigation/native';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { Image, Pressable, StyleSheet, View } from 'react-native';
import getCountryFromOffice, {
  COUNTRIES,
} from 'utilities/getCountryFromOffice';

import StyledText from 'components/StyledText';

import { ExtendedBoidCardModel } from 'models/BoidCardModel';

import UserService from 'services/user/UserService';

import COLORS from 'styles/colors';
import CONSTANTS from 'styles/constants';

import { CollectionStackPropList } from './CollectionNavigation';

export type ListItemProps = {
  boidCard: ExtendedBoidCardModel;
  isCollected: boolean;
};
function ListItem(props: ListItemProps): ReactElement {
  const { isCollected, boidCard } = props;
  const isMounted = useRef(false);

  const navigation = useNavigation<NavigationProp<CollectionStackPropList>>();

  const { firstName, lastName, office } = boidCard;
  const shortCountryName = useMemo(() => {
    const countryCode = getCountryFromOffice(office);
    return COUNTRIES[countryCode].shortName;
  }, [office]);
  //const [profileImage, setProfileImage] = useState<string>();
  const picture = useRef<string>();
  useEffect(() => {
    isMounted.current = true;
    if (boidCard) {
      UserService.getUserImage(boidCard.id).then((image) => {
        //setProfileImage(image);
        picture.current = image;
      });
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Pressable
      style={[styles.listContainer, { opacity: 1 }]}
      onPress={() => {
        navigation.navigate('CardDetailsScreen', { boidCard });
      }}
    >
      <Image source={{ uri: picture.current }} style={styles.profileImage} />

      <View style={styles.detailsContainer}>
        {firstName === 'ConnectingTheDots' ? (
          <StyledText style={styles.nameText}>Summit Fall 22</StyledText>
        ) : (
          <StyledText style={styles.nameText}>{firstName}</StyledText>
        )}
        <StyledText
          style={styles.lastNameText}
          ellipsizeMode="tail"
          numberOfLines={1}
        >
          {lastName}
        </StyledText>
        <StyledText style={styles.emailText}>{boidCard.email}</StyledText>
        <StyledText style={styles.officeText}>
          {office}, {shortCountryName}
        </StyledText>
      </View>
    </Pressable>
  );
}

export default ListItem;

const styles = StyleSheet.create({
  listContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: CONSTANTS.BORDER_RADIUS,
    //backgroundColor: COLORS.PRIMARY + '30', // appending opacity value to a HEX color
    backgroundColor: '#F7F5FF',
    padding: 8,
    maxWidth: '100%',
    height: 100,
    overflow: 'hidden',
    borderColor: COLORS.PRIMARY + '80',
    borderWidth: 3,
  },
  profileImage: {
    height: '100%',
    aspectRatio: 1,
    borderRadius: 50,
  },
  detailsContainer: {
    marginLeft: 8,
    alignSelf: 'flex-start',
    overflow: 'hidden',
    flex: 1,
  },
  nameText: {
    color: 'black',
    fontWeight: '600',
    fontSize: 20,
    textTransform: 'uppercase',
    marginBottom: 5,
  },
  lastNameText: {
    color: 'black',
    //fontWeight: '600',
    fontSize: 16,
    textTransform: 'uppercase',
    marginBottom: 5,
    //maxWidth: '100%',
  },
  emailText: {
    color: COLORS.PRIMARY,
    fontSize: 14,
    fontWeight: '400',
  },
  officeText: {
    color: COLORS.PRIMARY,
    fontSize: 14,
  },
  background: {
    ...StyleSheet.absoluteFillObject,
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
});
