import UserProfileModel from 'models/UserProfileModel';

export const COUNTRIES = {
  DE: {
    shortName: 'DE',
    longName: 'Germany',
  },
  NL: {
    shortName: 'NL',
    longName: 'Netherlands',
  },
  SE: {
    shortName: 'SE',
    longName: 'Sweden',
  },
  DK: {
    shortName: 'DK',
    longName: 'Denmark',
  },
  FI: {
    shortName: 'FI',
    longName: 'Finland',
  },
  CH: {
    shortName: 'CH',
    longName: 'Switzerland',
  },
  NO: {
    shortName: 'NO',
    longName: 'Norway',
  },
  NA: {
    shortName: 'NA',
    longName: 'Not Available',
  },
};

export type CountryCode = keyof typeof COUNTRIES;

function getCountryFromOffice(
  officeCity: UserProfileModel['office'],
): CountryCode {
  switch (officeCity) {
    case 'Munich':
    case 'Frankfurt':
    case 'Berlin':
    case 'Cologne':
    case 'Hamburg': {
      return 'DE';
    }
    case 'Stockholm': {
      return 'SE';
    }
    case 'Helsinki': {
      return 'FI';
    }
    case 'Oslo': {
      return 'NO';
    }
    case 'Zurich': {
      return 'CH';
    }
    case 'Copenhagen': {
      return 'DK';
    }
    case 'Amsterdam': {
      return 'NL';
    }
    default:
      return 'NA';
  }
}

export default getCountryFromOffice;
