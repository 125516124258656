import Config from 'config';

const clientId = '72884f1d-ed48-4e33-8ca0-530168b56f2b';
const tenantId = 'd4110288-bf1e-4337-97ab-a5c51371dcea';
const appScopes = [`api://717aaf54-6553-432e-85ca-b2399c7f87f6/.default`];
const discoveryUrl = `https://login.microsoftonline.com/${tenantId}/v2.0`;
const tokenUrl = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/token`;
const appUriSchema = Config.appSchema;
const authCallbackPath = 'auth';

export default {
  clientId,
  appScopes,
  tenantId,
  discoveryUrl,
  appUriSchema,
  authCallbackPath,
  tokenUrl,
};
