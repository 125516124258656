import { Camera } from 'expo-camera';
import { useEffect, useState } from 'react';

export default function useGetCameraPermission() {
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);

  useEffect(() => {
    async function askForCameraPermission() {
      try {
        setHasPermission(true);
      } catch (e) {
        console.warn(e);
      }
    }

    askForCameraPermission();
  }, []);

  return hasPermission;
}
