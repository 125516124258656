import React, { ReactElement } from 'react';
import { ActivityIndicator, Platform, StyleSheet, View } from 'react-native';

import COLORS from 'styles/colors';

import StyledText from './StyledText';

export type LoadingIndicatorProps = {
  text?: string;
  color?: string;
  textColor?: string;
};

function LoadingIndicator(props: LoadingIndicatorProps): ReactElement {
  const { text, color = COLORS.PRIMARY, textColor = 'black' } = props;

  return (
    <View style={styles.container}>
      <ActivityIndicator
        color={Platform.OS === 'ios' ? undefined : color}
        size="large"
      />
      <StyledText style={[styles.statusText, { color: textColor }]}>
        {text}
      </StyledText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusText: {
    marginTop: 10,
    fontWeight: '300',
  },
});

export default LoadingIndicator;
