import {
  makeRedirectUri,
  useAuthRequest,
  useAutoDiscovery,
} from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';
import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { Platform } from 'react-native';

import { upsertUserProfile } from 'services/user/UserService';

import AuthConfig from './AuthConfig';
import AuthManager from './AuthManager';

if (Platform.OS === 'web') {
  WebBrowser.maybeCompleteAuthSession();
}

type AuthState = {
  isLoading: boolean;
  accessToken: string | null;
};

const defaultAuthState: AuthState = {
  isLoading: true,
  accessToken: null,
};

type RestoreTokenAction = {
  type: 'RESTORE_TOKEN';
  accessToken: string | null;
};

type SignInAction = {
  type: 'SIGN_IN';
  accessToken: string;
};

type SignOutAction = {
  type: 'SIGN_OUT';
};

type AuthAction = RestoreTokenAction | SignInAction | SignOutAction;

function authReducer(prevState: AuthState, action: AuthAction) {
  switch (action.type) {
    case 'RESTORE_TOKEN':
      return {
        ...prevState,
        isLoading: false,
        accessToken: action.accessToken,
      };
    case 'SIGN_IN':
      return {
        ...prevState,
        isLoading: false,
        accessToken: action.accessToken,
      };
    case 'SIGN_OUT':
      return {
        ...prevState,
        accessToken: null,
      };
  }
}

type AuthContextType = {
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
  refreshToken: () => Promise<void>;
  state: AuthState;
};

const defaultContextValue: AuthContextType = {
  signIn: async () => {},
  signOut: async () => {},
  refreshToken: async () => {},
  state: defaultAuthState,
};

const AuthContext = React.createContext<AuthContextType>(defaultContextValue);

export function AuthContextProvider(
  props: PropsWithChildren<unknown>,
): ReactElement {
  const { children } = props;

  const [state, dispatch] = useReducer(authReducer, defaultAuthState);

  const redirectUri = useMemo(() => {
    return makeRedirectUri({
      scheme: AuthConfig.appUriSchema,
      path: AuthConfig.authCallbackPath,
    });
  }, []);

  const discovery = useAutoDiscovery(AuthConfig.discoveryUrl);

  const [request, , promptAsync] = useAuthRequest(
    {
      clientId: AuthConfig.clientId,
      scopes: AuthConfig.appScopes,
      redirectUri,
    },
    discovery,
  );

  const signIn = useCallback(async () => {
    const result = await promptAsync();

    if (result === null || result.type !== 'success') {
      console.log('Result not loaded or invalid');
      return;
    }

    const accessToken = await AuthManager.handleSignInResult(
      result.params.code,
      redirectUri,
      request?.codeVerifier,
    );

    dispatch({ type: 'SIGN_IN', accessToken });

    // Create or update user profile information
    await upsertUserProfile();
  }, [promptAsync, redirectUri, request?.codeVerifier]);

  const signOut = useCallback(async () => {
    await AuthManager.signOut();

    dispatch({ type: 'SIGN_OUT' });
  }, [dispatch]);

  const refreshToken = useCallback(async () => {
    const accessToken = await AuthManager.refreshToken();

    dispatch({ type: 'RESTORE_TOKEN', accessToken });
  }, [dispatch]);

  useEffect(() => {
    refreshToken();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const authContext = useMemo(
    () => ({
      signIn,
      signOut,
      refreshToken,
      state,
      dispatch,
    }),
    [signIn, signOut, refreshToken, state, dispatch],
  );

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
}

export default AuthContext;
