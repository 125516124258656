import { Offices } from 'models/UserProfileModel';

export type FilterOption = {
  label: string;
  value: any;
};

export type SortFilterOption = {
  label: string;
  value: string;
};

export type FilterCategory = {
  category: FilterCategories;
  options: FilterOption[];
};

export type FilterCategoriesProps = {
  filters: FilterCategory[];
  sortOptions: SortFilterOption[];
};

export enum FilterCategories {
  LOCATION = 'Location',
  POSITION = 'Position',
}

export const FilterScheme: FilterCategoriesProps = {
  filters: [
    {
      category: FilterCategories.LOCATION,
      options: [
        { label: 'Berlin', value: Offices.Berlin },
        { label: 'Hamburg', value: Offices.Hamburg },
        { label: 'Munich', value: Offices.Munich },
        { label: 'Cologne', value: Offices.Cologne },
        { label: 'Stockholm', value: Offices.Stockholm },
        { label: 'Oslo', value: Offices.Oslo },
        { label: 'Zurich', value: Offices.Zurich },
        { label: 'Frankfurt', value: Offices.Frankfurt },
        { label: 'Copenhagen', value: Offices.Copenhagen },
        { label: 'Amsterdam', value: Offices.Amsterdam },
      ],
    },
    // {
    //   category: FilterCategories.POSITION,
    //   options: [
    //     { label: 'TS', value: 'TS' },
    //     { label: 'ES', value: 'ES' },
    //     { label: 'DevOps', value: 'DevOps' },
    //   ],
    // },
  ],
  sortOptions: [
    { label: 'Name', value: 'firstName' },
    { label: 'Date', value: 'date' },
  ],
};
