import MaterialIcon from '@expo/vector-icons/MaterialIcons';
import {
  NavigationProp,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import Config from 'config';
import React, { ReactElement, useCallback, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import RoundedButton from 'components/RoundedButton';

import { MainTabPropList } from '../MainNavigation';
import QrScanner from './QrScanner';
import { ScanStackPropList } from './ScanNavigation';

function resetTimer(
  timer: React.MutableRefObject<number | undefined>,
  callback: () => void,
) {
  clearTimeout(timer.current);
  timer.current = setTimeout(callback, 3000) as unknown as number;
}

function ScanScreen(): ReactElement {
  const mainNavigaion = useNavigation<NavigationProp<MainTabPropList>>();
  const scanScreenNavigation =
    useNavigation<NavigationProp<ScanStackPropList>>();
  const [scannerDisabled, setScannerDisabled] = useState<boolean>(true);
  const [scanningText, setScanningText] = useState<string>(
    'Scanning BoidCode...',
  );
  const [scannedBoidCode, setScannedBoidCode] = useState('');
  const timer = useRef<number | undefined>(undefined);

  const handleCodeScanned = useCallback((qrData: string) => {
    setScannerDisabled(true);
    const isValidBoidCode = qrData.startsWith(Config.boidCodePrefix);
    if (isValidBoidCode) {
      const id = qrData.slice(Config.boidCodePrefix.length);
      setScannedBoidCode(id);
      scanScreenNavigation.navigate('SuccessfulScanScreen', { id });
    } else {
      setScanningText('Not a valid BoidCode');
      resetTimer(timer, () => setScanningText('Scanning BoidCode...'));
      setTimeout(() => {
        setScannerDisabled(false);
      }, 50);
    }
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      setScannerDisabled(false);
    }, []),
  );

  const onPressBack = () => {
    setScannerDisabled(true);
    mainNavigaion.goBack();
  };

  return (
    // Do not use SafeAreaView here to make the video feed fill the whole screen
    <View style={StyleSheet.absoluteFill}>
      <QrScanner
        disabled={scannerDisabled}
        onCodeScanned={handleCodeScanned}
        hintText={scanningText}
      />
      <RoundedButton
        onPress={onPressBack}
        style={styles.closeScannerButton}
        icon={<MaterialIcon name="arrow-back" color={'white'} size={26} />}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  closeScannerButton: {
    zIndex: 100,
    position: 'absolute',
    left: 24,
    top: 40,
  },
});

export default ScanScreen;
