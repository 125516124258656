import { ReactElement } from 'react';
import { StyleSheet, Text, TextProps } from 'react-native';

export type StyledTextProps = TextProps;

function StyledText(props: StyledTextProps): ReactElement {
  const { style } = props;

  return <Text {...props} style={[style, styles.default]} />;
}

const styles = StyleSheet.create({
  default: {
    fontFamily: 'ProximaNova',
  },
});

export default StyledText;
