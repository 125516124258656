import React, { ReactElement } from 'react';
import { Dimensions, Image, StyleSheet, View } from 'react-native';

import StyledText from 'components/StyledText';

import IntroSlider1 from 'assets/images/IntroSlider1';
import IntroSlider2 from 'assets/images/IntroSlider2';
import IntroSlider3 from 'assets/images/IntroSlider3';

import CONSTANTS from 'styles/constants';

export type IntroCardProps = {
  id: string;
  title: string;
  description: string;
};

function IntroCard(props: IntroCardProps): ReactElement {
  const { id, title, description } = props;

  const { width } = Dimensions.get('window');

  return (
    <View style={[{ width }, styles.pageWrapper]}>
      <View style={styles.cardContainer}>
        <StyledText style={styles.header}>{title}</StyledText>
        {id == '1' && <IntroSlider1 />}

        {id == '2' && <IntroSlider2 />}

        {id == '3' && <IntroSlider3 />}

        <StyledText style={styles.paragraph}>{description}</StyledText>
      </View>
    </View>
  );
}

export default IntroCard;

const styles = StyleSheet.create({
  pageWrapper: {
    padding: 20,
    paddingTop: 40,
  },
  cardContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    height: '100%',
    shadowColor: '#171717',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.1,
    shadowRadius: 30,
    borderRadius: CONSTANTS.BORDER_RADIUS,
    textAlign: 'center',
    minHeight: '500px'
  },
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 35,
    marginBottom: 45,
  },
  paragraph: {
    fontSize: 18,
    margin: 30,
    marginTop: 45,
  },
});
