// eslint-disable-next-line import/no-extraneous-dependencies
import Constants from 'expo-constants';

const apiBaseUrl = `https://${Constants.manifest?.extra?.apiHost}`;
const appSchema = 'com.netlight.boidbook';
const boidCodePrefix = `${appSchema}://`;
const slackTeamId = 'T0252T2EC';

export default {
  apiBaseUrl,
  appSchema,
  boidCodePrefix,
  slackTeamId,
};
