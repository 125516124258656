import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Animated, Dimensions, StyleSheet, View } from 'react-native';
import ConfettiCannon from 'react-native-confetti-cannon';
import { SafeAreaView } from 'react-native-safe-area-context';

import BackOfCard from 'components/BackOfCard';
import LoadingIndicator from 'components/LoadingIndicator';
import PrimaryGradient from 'components/PrimaryGradient';
import StyledButton, {
  ButtonColors,
  ButtonSize,
} from 'components/StyledButton';
import StyledText from 'components/StyledText';

import { ExtendedBoidCardModel } from 'models/BoidCardModel';

import BoidCardService from 'services/boid-card/BoidCardService';
import ConnectionService from 'services/connection/ConnectionService';

import BoidNotFound from 'assets/images/BoidNotFound';

import COLORS from 'styles/colors';

import { MainTabPropList } from '../MainNavigation';
import { ScanStackPropList } from './ScanNavigation';

const windowWidth = Dimensions.get('window').width;

export type SuccessfulScanScreenProps = {
  id: string;
};

// TODO: replace StackScreenProps with "normal" props (just pass boidCode to this component)
function SuccessfulScanScreen(
  props: StackScreenProps<ScanStackPropList>,
): ReactElement {
  const {
    navigation,
    route: { params },
  } = props;

  const [id, setId] = useState<string>('');

  useEffect(() => {
    if (params?.id) {
      setId(params.id);
    }
  }, [params?.id]);

  const [collectionCheckLoading, setcollectionCheckLoading] =
    useState<boolean>(true);
  const [boidAlreadyCollected, setBoidAlreadyCollected] = useState<boolean>();

  useEffect(() => {
    if (id) {
      ConnectionService.getConnections()
        .then((connectionInfo) => {
          const list =
            connectionInfo?.map(
              (cardId: { otherUserId: string }) => cardId.otherUserId,
            ) ?? [];

          setBoidAlreadyCollected(list.includes(id));
        })
        .finally(() => setcollectionCheckLoading(false));
    }
  }, [id]);

  const [boidCard, setBoidCard] = useState<ExtendedBoidCardModel>();
  const [boidCardLoading, setBoidCardLoading] = useState<boolean>(true);
  const [notFoundText, setNotFoundText] = useState<string>();

  useEffect(() => {
    if (id) {
      BoidCardService.getMyExtendedBoidCard().then((item) => {
        if (id === item.id) {
          setNotFoundText("You can't add yourself! ;)");
          setBoidCard(undefined);
          setBoidCardLoading(false);
        } else {
          setNotFoundText('Boid not found :(');
          BoidCardService.getExtendedBoidCard(id)
            .then(setBoidCard)
            .catch(() => setBoidCard(undefined))
            .finally(() => setBoidCardLoading(false));
        }
      });
    }
  }, [id]);

  const pageLoading = useMemo(() => {
    return boidCardLoading || collectionCheckLoading;
  }, [boidCardLoading, collectionCheckLoading]);

  useEffect(() => {
    // Call backend to initiate collection flow
    if (!pageLoading && !boidAlreadyCollected && boidCard) {
      ConnectionService.addConnection(id);
    }
  }, [pageLoading, boidAlreadyCollected, boidCard]);

  if (pageLoading) {
    return <LoadingView />;
  }
  if (!boidCard) {
    return <BoidNotFoundView text={notFoundText} />;
  }

  if (boidAlreadyCollected) {
    return <BoidAlreadyCollectedView boidCard={boidCard} />;
  }

  return <BoidAddedView boidCard={boidCard} />;
}

export default SuccessfulScanScreen;

function LoadingView(): ReactElement {
  return (
    <SafeAreaView style={styles.loadingPage}>
      <PrimaryGradient />
      <LoadingIndicator
        text="Finding Boid..."
        color={COLORS.PRIMARY}
        textColor={COLORS.PRIMARY}
      />
    </SafeAreaView>
  );
}

type BoidAddedViewProps = {
  boidCard: ExtendedBoidCardModel;
};
function BoidAddedView(props: BoidAddedViewProps): ReactElement {
  const { boidCard } = props;
  return (
    <View style={styles.boidAlreadyCollectedPage}>
      <PrimaryGradient />
      <TopCard text="New Boid found! Added to each others collection." />
      {/* TODO: Add a animation to move from B&W to color  <BoidCard boidCard={boidCard} />*/}
      <View style={styles.boidCardContainer}>
        <Animated.View style={styles.card}>
          <BackOfCard
            boidCard={boidCard}
            showNotes={true}
            showTimeOfConnecton={false}
            flipCardFunction={undefined}
            showBoidQrCode={false}
            profileImage={boidCard.profilePicture}
          />
        </Animated.View>
      </View>
      <ConfettiCannon
        count={100}
        origin={{ x: windowWidth / 2, y: 50 }}
        fallSpeed={5000}
        autoStartDelay={150}
      />
      <ButtonComponent />
    </View>
  );
}

type BoidAlreadyCollectedViewProps = {
  boidCard: ExtendedBoidCardModel;
};
function BoidAlreadyCollectedView(
  props: BoidAlreadyCollectedViewProps,
): ReactElement {
  const { boidCard } = props;
  return (
    <View style={styles.boidAlreadyCollectedPage}>
      <PrimaryGradient />
      <TopCard text="Boid already in collection!" />
      <View style={styles.boidCardContainer}>
        <Animated.View style={styles.card}>
          <BackOfCard
            boidCard={boidCard}
            showNotes={true}
            showTimeOfConnecton={false}
            flipCardFunction={undefined}
            showBoidQrCode={false}
            profileImage={boidCard.profilePicture}
          />
        </Animated.View>
      </View>
      <ButtonComponent />
      {/* TODO: Add a shaking animation to the boid card */}
    </View>
  );
}

type BoidNotFoundProps = {
  text?: string;
};

function BoidNotFoundView(props: BoidNotFoundProps): ReactElement {
  const { text } = props;
  return (
    <SafeAreaView style={styles.boidNotFoundPage}>
      <PrimaryGradient />
      <TopCard text={text} />
      <View style={styles.boidNotFoundContainer}>
        <BoidNotFound />
      </View>
      <ButtonComponent />
    </SafeAreaView>
  );
}

type TopCardCardProps = {
  text?: string;
  children?: ReactNode;
};
function TopCard(props: TopCardCardProps): ReactElement {
  const { children, text } = props;
  return (
    <View style={styles.topCard}>
      {text && <StyledText style={styles.bottomCardText}>{text}</StyledText>}
      <View style={[styles.bottomCardButtons, { paddingTop: text ? 0 : 48 }]}>
        {children}
      </View>
    </View>
  );
}

function ButtonComponent(): ReactElement {
  const navigationNavBar = useNavigation<NavigationProp<MainTabPropList>>();
  const navigationScan = useNavigation<NavigationProp<ScanStackPropList>>();

  return (
    <View style={styles.ButtonComponent}>
      <StyledButton
        color={ButtonColors.SECONDARY}
        title="Continue Scanning!"
        size={ButtonSize.SMALL}
        startIcon={
          <MaterialIcons color={COLORS.PRIMARY} name="arrow-back" size={16} />
        }
        onPress={() => navigationScan.navigate('ScannerScreen')}
      />
      <StyledButton
        size={ButtonSize.SMALL}
        title="Go to collection!"
        endIcon={
          <MaterialIcons color={COLORS.WHITE} name="arrow-forward" size={16} />
        }
        onPress={() => navigationNavBar.navigate('CollectionNavigation')}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  boidCardContainer: {
    textAlign: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    paddingTop: 5,
    paddingBottom: 5,
    width: '90%',
    height: '75%',
    maxHeight: innerHeight - innerHeight / 4,
    maxWidth: innerHeight - innerHeight / 7,
  },
  card: {
    borderRadius: 35,
    borderColor: '#4A49CB',
    borderWidth: 7,
    borderStyle: 'solid',
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: COLORS.BACKGROUND,
    alignItems: 'center',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
  },
  loadingPage: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  boidNotFoundPage: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  boidNotFoundContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.PRIMARY,
    borderRadius: 360,
    borderColor: '#4A49CB',
    borderWidth: 3,
    borderStyle: 'solid',
    margin: 20,
  },
  boidAlreadyCollectedPage: {
    flex: 1,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  topCard: {
    width: '100%',
    alignContent: 'flex-start',
    backgroundColor: COLORS.PRIMARY,
    height: '7%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottomCardButtons: {
    paddingHorizontal: 3,
    paddingBottom: 3,
  },
  bottomCardText: {
    color: COLORS.WHITE,
    fontSize: 20,
    fontWeight: '600',
    padding: 10,
    textAlign: 'center',
  },
  ButtonComponent: {
    flexDirection: 'row',
    paddingTop: 5,
    alignSelf: 'stretch',
    justifyContent: 'space-evenly',
  },
});
