import MaterialIcon from '@expo/vector-icons/MaterialIcons';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Pressable, StyleProp, StyleSheet, ViewStyle } from 'react-native';

import COLORS from 'styles/colors';

// circular button with left-pointing arrow

export type RoundedButtonProps = {
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
  icon?: ReactNode;
  smallButton?: boolean;
  materialIconName?: any;
};

function RoundedButton(props: RoundedButtonProps): ReactElement {
  const {
    style,
    onPress = () => {},
    icon,
    smallButton,
    materialIconName,
  } = props;

  const iconDeviderNumber = 20;

  function calculateMaxIconSize(): number {
    if (innerWidth / iconDeviderNumber > 60) {
      return 50;
    } else {
      return innerWidth / iconDeviderNumber;
    }
  }

  if (smallButton) {
    return (
      <Pressable style={[style, styles.smallButton]} onPress={onPress}>
        <MaterialIcon
          name={materialIconName}
          size={calculateMaxIconSize()}
          style={{ color: 'white' }}
        />
      </Pressable>
    );
  }

  return (
    <Pressable onPress={onPress} style={[style, styles.button]}>
      {icon}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  button: {
    zIndex: 100,
    borderRadius: 1000,
    width: 50,
    height: 50,
    backgroundColor: COLORS.PRIMARY,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: '#000000',
    shadowOpacity: 0.15,
    shadowRadius: 10,
    shadowOffset: {
      width: 0,
      height: 1,
    },
  },
  smallButton: {
    zIndex: 100,
    borderRadius: 1000,
    maxHeight: 80,
    maxWidth: 80,
    width: innerWidth / 10,
    height: innerWidth / 10,
    backgroundColor: COLORS.PRIMARY,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-end',
  },
});

export default RoundedButton;
