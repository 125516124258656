import MaterialIcon from '@expo/vector-icons/MaterialIcons';
import { ReactElement, ReactNode, useState } from 'react';
import {
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputProps,
  View,
  ViewStyle,
} from 'react-native';

import COLORS from 'styles/colors';

export type SearchBarProps = {
  style?: StyleProp<ViewStyle>;
  cancelEnabled?: boolean;
  filterButton: ReactNode;
  onCancel?: () => void;
  onClear?: () => void;
  onFocusSearchbar?: () => void;
} & Omit<TextInputProps, 'style'>;
function SearchBar(props: SearchBarProps): ReactElement {
  const {
    style,
    cancelEnabled,
    filterButton,
    onCancel = () => {},
    onClear = () => {},
    onFocusSearchbar = () => {},
    ...textInputProps
  } = props;
  const [focusInput, setFocusInput] = useState(false);

  return (
    <View
      style={[
        style,
        styles.searchBarContainer,
        focusInput ? styles.searchBarContainerFocus : null,
      ]}
    >
      <MaterialIcon
        name={cancelEnabled ? 'arrow-back' : 'search'}
        size={25}
        style={[styles.searchIcon, focusInput ? styles.searchIconFocus : null]}
        onPress={() => {
          if (cancelEnabled) onCancel();
        }}
      />

      <TextInput
        {...textInputProps}
        style={[styles.searchBarInput]}
        onFocus={() => {
          setFocusInput(true);
          onFocusSearchbar();
        }}
        onBlur={() => {
          setFocusInput(false);
        }}
      />

      {/* {!!textInputProps.value && (
        <MaterialIcon
          name="cancel"
          size={16}
          style={styles.clearIcon}
          onPress={onClear}
        />
      )} */}
      {filterButton}
    </View>
  );
}

export default SearchBar;

const styles = StyleSheet.create({
  searchBarContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    borderWidth: 2,
    borderColor: COLORS.PRIMARY + '80',
    borderRadius: 20,
    backgroundColor: '#F7F5FF',
  },
  searchBarContainerFocus: {
    borderColor: COLORS.PRIMARY,
  },
  searchIcon: {
    paddingHorizontal: 2,
    color: COLORS.PRIMARY + '80',
  },
  searchIconFocus: {
    color: COLORS.PRIMARY,
  },
  clearIcon: {
    marginLeft: -16,
    color: COLORS.PRIMARY,
  },
  searchBarInput: {
    flexGrow: 1,
    fontSize: 16,
    outlineStyle: 'none',
  },
});
