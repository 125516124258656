const COLORS = {
  BACKGROUND: '#F6F5FF',
  PRIMARY: '#6559FF',
  SECONDARY: '#EFEDFF',
  SECONDARYBORDER: '#B0A8FF',
  LIGHT_GRAY: '#C9C9C9',
  GRAY: '#959595',
  DARK_GRAY: '#606060',
  SUPER_LIGHT_GRAY: '#F4F4FC',
  CONFIRM: '#69DA89',
  WHITE: '#FFFFFF',
  TRANSPARENT: 'transparent',
  BLACK: 'black',
};

export default COLORS;
