import MaterialIcon from '@expo/vector-icons/MaterialIcons';
import { useIsFocused } from '@react-navigation/native';
import { BarCodeScannedCallback, BarCodeScanner } from 'expo-barcode-scanner';
import { Camera } from 'expo-camera';
import { CameraType } from 'expo-camera/build/Camera.types';
import useGetCameraPermission from 'hooks/useGetCameraPermission';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { StyleSheet, Vibration, View } from 'react-native';

import LoadingIndicator from 'components/LoadingIndicator';
import PrimaryGradient from 'components/PrimaryGradient';
import RoundedButton from 'components/RoundedButton';
import StyledText from 'components/StyledText';

import ScanFrame from 'assets/images/ScanFrame';

import CONSTANTS from 'styles/constants';

export type QrScannerProps = {
  onCodeScanned: (data: string) => void;
  disabled?: boolean;
  hintText?: string;
};

function QrScanner(props: QrScannerProps): ReactElement {
  const { disabled = false, onCodeScanned, hintText } = props;
  const isFocused: boolean = useIsFocused();
  const ghostScanPreventionActive = useRef<boolean>(true);
  const lastScannedCode = useRef<string>('');
  const hasPermission = useGetCameraPermission();
  const [cameraType, setCameraType] = useState<boolean>(true);
  const handleBarCodeScanned: BarCodeScannedCallback = (params) => {
    Vibration.vibrate();
    // As described below, we cannot use an early return in here to check if the code was already scanned.
    // Prevent ghost scan after reactivation due to caching
    if (
      ghostScanPreventionActive.current &&
      lastScannedCode.current === params.data
    ) {
      return;
    }
    // Store last scanned code to be used by the ghost scan prevention logic
    lastScannedCode.current = params.data;

    onCodeScanned(params.data);
  };

  useEffect(() => {
    if (disabled) {
      // Mark debounce as active, this will prime the logic for preventing a ghost scan after we enable scanning again
      ghostScanPreventionActive.current = true;
    } else {
      // After a short timeout, we can disable the logic to prevent ghost scans again (this allow to scan the same ode again)
      setTimeout(() => {
        ghostScanPreventionActive.current = false;
      }, 500);
    }
  }, [disabled]);

  if (hasPermission === null) {
    return (
      <View style={styles.infoContainer}>
        <PrimaryGradient />
        <LoadingIndicator
          text="Initializing Camera..."
          color="white"
          textColor="white"
        />
      </View>
    );
  }

  if (hasPermission === false) {
    return (
      <View style={styles.infoContainer}>
        <PrimaryGradient />
        <StyledText style={styles.infoContainerText}>
          No camera access.{'\n'}
          Please enable camera permissions in your device settings!
        </StyledText>
      </View>
    );
  }
  return (
    <>
      <Camera
        style={styles.scanner}
        barCodeScannerSettings={{
          barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
        }}
        type={cameraType ? CameraType.back : CameraType.front}
        // Due to some strange behavior, the handler function cannot access the current value of the state.
        // Hence we cannot check inside the handler wether "scanned" is true and use this officially documented workaround.
        onBarCodeScanned={disabled ? undefined : handleBarCodeScanned}
      />
      {hintText && (
        <View style={styles.overlayContainer}>
          <ScanFrame style={styles.scanFrame} />
          <RoundedButton
            style={styles.hintContainer}
            onPress={() => {
              setCameraType(!cameraType);
            }}
            icon={
              <MaterialIcon name="flip-camera-ios" color={'white'} size={26} />
            }
          />
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  scanner: {
    ...StyleSheet.absoluteFillObject,
    height: '100%',
  },
  overlayContainer: {
    ...StyleSheet.absoluteFillObject,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ff000030',
  },
  scanFrame: {
    width: '60%',
    color: 'white',
    zIndex: 10,
    opacity: 0.5,
  },
  cameraOverlay: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  infoContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  infoContainerText: {
    zIndex: 100,
    textAlign: 'center',
    color: 'white',
  },
  hintContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: CONSTANTS.BORDER_RADIUS,
    padding: 10,
    zIndex: 11,
    marginTop: 25,
  },
  hintContainerText: {
    color: '#030303',
    fontWeight: 'bold',
  },
  flipButton: {
    bottom: 0,
  },
});

export default QrScanner;
