import * as React from 'react';
import Svg, { Circle, Ellipse, Path, SvgProps } from 'react-native-svg';

// dead looking bird, shown in collection screen when no boid found

function BoidNotFound(props: SvgProps) {
  return (
    <Svg viewBox="0 0 116 67" width={116} height={67} fill="none" {...props}>
      <Ellipse cx={58.366} cy={60.073} rx={13.857} ry={5.879} fill="#F2F2F2" />
      <Ellipse
        cx={28.273}
        cy={47.362}
        rx={29.393}
        ry={5.039}
        transform="rotate(28.276 28.273 47.362)"
        fill="#F2F2F2"
      />
      <Ellipse
        cx={30.037}
        cy={55.252}
        rx={24.524}
        ry={5.039}
        transform="rotate(14.797 30.037 55.252)"
        fill="#F2F2F2"
      />
      <Ellipse
        cx={33.246}
        cy={60.953}
        rx={20.851}
        ry={5.039}
        transform="rotate(-.111 33.246 60.953)"
        fill="#F2F2F2"
      />
      <Ellipse
        rx={29.393}
        ry={5.039}
        transform="scale(-1 1) rotate(28.276 -137.543 -149.132)"
        fill="#F2F2F2"
      />
      <Ellipse
        rx={24.524}
        ry={5.039}
        transform="scale(-1 1) rotate(14.797 -255.393 -300.801)"
        fill="#F2F2F2"
      />
      <Ellipse
        rx={20.851}
        ry={5.039}
        transform="matrix(-1 -.00194 -.00194 1 82.086 60.953)"
        fill="#F2F2F2"
      />
      <Circle cx={58.5} cy={34.5} r={26.5} fill="#F2F2F2" />
      <Path
        d="M78 30.611 76.389 29 70 35.389 63.611 29 62 30.611 68.389 37 62 43.389 63.611 45 70 38.611 76.389 45 78 43.389 71.611 37 78 30.611ZM55 30.611 53.389 29 47 35.389 40.611 29 39 30.611 45.389 37 39 43.389 40.611 45 47 38.611 53.389 45 55 43.389 48.611 37 55 30.611Z"
        fill="#4A49CB"
      />
      <Path
        d="M57.308 13.357c3.322-4-1.145-8.534-3.794-10.3"
        stroke="#F2F2F2"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <Path
        d="M60.561 13.357c-3.322-4 1.145-8.534 3.794-10.3"
        stroke="#F2F2F2"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <Path
        d="M56.713 1c4.197 1.338 1.567 11.355-.273 16.196"
        stroke="#F2F2F2"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <Path d="m58.5 58-7.361-9H65.86L58.5 58Z" fill="#4A49CB" />
    </Svg>
  );
}

export default BoidNotFound;
