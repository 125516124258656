import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Animated, Image, StyleSheet, View } from 'react-native';
import getCountryFromOffice, {
  COUNTRIES,
} from 'utilities/getCountryFromOffice';

import BackOfCard from 'components/BackOfCard';
import RoundedButton from 'components/RoundedButton';

import { ExtendedBoidCardModel } from 'models/BoidCardModel';

import UserService from 'services/user/UserService';

import COLORS from 'styles/colors';
import CONSTANTS from 'styles/constants';

import Office from './Office';
import SlackReachOutButton from './SlackReachOutButton';
import StyledText from './StyledText';

export type BoidCardProps = {
  boidCard: ExtendedBoidCardModel;
  showQRcode?: Boolean | undefined;
  myCard?: Boolean | undefined;
};

function BoidCard(props: BoidCardProps): ReactElement {
  const { boidCard, showQRcode, myCard } = props;
  const shortCountryName = useMemo(() => {
    const countryCode = getCountryFromOffice(boidCard.office);
    return COUNTRIES[countryCode].shortName;
  }, [boidCard.office]);

  const [flipState, setFlipState] = useState<'front' | 'back'>('front');
  const flipAnimation = useRef(new Animated.Value(0)).current;
  const [iconName, setIconName] = useState<string>('flip-camera-android');
  useEffect(() => {
    Animated.timing(flipAnimation, {
      toValue: flipState === 'front' ? 0 : 180,
      duration: 300,
      useNativeDriver: true,
    }).start();
  }, [flipState]); // eslint-disable-line react-hooks/exhaustive-deps

  const flipToFrontStyle = {
    transform: [
      {
        rotateY: flipAnimation.interpolate({
          inputRange: [0, 180],
          outputRange: ['0deg', '180deg'],
        }),
      },
    ],
  };
  const flipToBackStyle = {
    transform: [
      {
        rotateY: flipAnimation.interpolate({
          inputRange: [0, 180],
          outputRange: ['180deg', '360deg'],
        }),
      },
    ],
  };

  function flipCard(): void {
    setFlipState((curr) => (curr === 'front' ? 'back' : 'front'));
  }

  const [profileImage, setProfileImage] = useState<string>();

  useEffect(() => {
    if (boidCard) {
      UserService.getUserImage(boidCard.id).then((image) =>
        setProfileImage(image),
      );
    }
    if (myCard) {
      setIconName('qr-code-scanner');
    }
  }, [boidCard]);

  return (
    <View style={styles.boidCardContainer}>
      <Animated.View style={[styles.card, styles.cardFront, flipToFrontStyle]}>
        <View style={styles.background} />
        <RoundedButton
          onPress={() => flipCard()}
          smallButton={true}
          materialIconName={iconName}
        />
        <Image source={{ uri: profileImage }} style={[styles.profileImage]} />

        {boidCard.firstName === 'ConnectingTheDots' ? (
          <>
            <StyledText
              style={boidCard.lastName ? styles.nameText : styles.eventNameText}
            >
              Connecting the Dots
            </StyledText>
            <StyledText style={styles.emailText}>SUMMIT FALL 2022</StyledText>
          </>
        ) : (
          <>
            <StyledText
              style={boidCard.lastName ? styles.nameText : styles.eventNameText}
            >
              {boidCard.firstName} {boidCard.lastName}
            </StyledText>
          </>
        )}
        <View
          style={{
            width: '80%',
            borderBottomColor: COLORS.PRIMARY,
            borderBottomWidth: 2,
            alignSelf: 'center',
            marginBottom: 10,
            marginTop: 5,
          }}
        ></View>
        {boidCard.office && (
          <View style={styles.officeAndSlackContainer}>
            <View style={styles.officeContainer}>
              <Office office={boidCard.office} style={styles.officeImage} />
              <StyledText style={styles.officeText}>
                {boidCard.office}
              </StyledText>
            </View>

            {!myCard && boidCard.slackUserId && (
              <View style={styles.slackContainer}>
                <SlackReachOutButton
                  userId={boidCard.slackUserId}
                  transparant={true}
                />
              </View>
            )}
          </View>
        )}
      </Animated.View>

      <Animated.View style={[styles.card, styles.cardBack, flipToBackStyle]}>
        {showQRcode ? (
          <BackOfCard
            boidCard={boidCard}
            profileImage={undefined}
            showNotes={false}
            showTimeOfConnecton={false}
            flipCardFunction={flipCard}
            showBoidQrCode={true}
          />
        ) : (
          <BackOfCard
            boidCard={boidCard}
            profileImage={undefined}
            showNotes={true}
            showTimeOfConnecton={true}
            flipCardFunction={flipCard}
            showBoidQrCode={false}
          />
        )}
      </Animated.View>
    </View>
  );
}

const styles = StyleSheet.create({
  boidCardContainer: {
    width: '100%',
    textAlign: 'center',
    marginBottom: 20,
  },
  card: {
    borderRadius: 35,
    borderColor: '#4A49CB',
    borderWidth: 7,
    borderStyle: 'solid',
    padding: 24,
    backfaceVisibility: 'hidden',
    alignItems: 'center',
    overflow: 'hidden',
    shadowColor: '#000000',
    minHeight: 435,
    maxHeight: innerHeight - innerHeight / 11,
  },
  cardFront: {},
  cardBack: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: COLORS.BACKGROUND,
  },
  background: {
    ...StyleSheet.absoluteFillObject,
    width: '100%',
    height: '100%',
    zIndex: -1,
    backgroundColor: COLORS.BACKGROUND,
  },
  profileImage: {
    margin: 7,
    width: '100%',
    maxHeight: innerHeight - innerHeight / 7,
    maxWidth: innerHeight - innerHeight / 7,
    aspectRatio: 1,
    borderRadius: 360,
    borderColor: '#000000',
    borderWi12dth: 3,
  },
  nameText: {
    width: '100%',
    marginTop: 24,
    color: 'black',
    fontWeight: '700',
    fontSize: 32,
  },
  eventNameText: {
    width: '100%',
    marginTop: 24,
    fontWeight: '700',
    fontSize: 32,
    color: 'black',
  },
  emailText: {
    marginTop: 12,
    fontSize: 18,
    fontWeight: '400',
    color: COLORS.BLACK,
  },
  officeText: {
    marginTop: 8,
    fontSize: 14,
    color: 'black',
  },
  qrContainer: {
    backgroundColor: 'white',
    borderRadius: CONSTANTS.BORDER_RADIUS,
    padding: 32,
    alignItems: 'center',
    marginTop: 16,
  },
  scanInfoText: {
    marginTop: 16,
    textAlign: 'center',
    fontWeight: '700',
    color: COLORS.PRIMARY,
  },
  officeAndSlackContainer: {
    flexDirection: 'row',
  },
  officeContainer: {
    flexDirection: 'row',
    marginRight: 5,
  },
  slackContainer: {
    marginLeft: 5,
  },
  officeImage: {
    width: 18,
    aspectRatio: 1,
    marginRight: 10,
  },
});

export default BoidCard;
