import React, { ReactElement, useState } from 'react';
import {
  Pressable,
  StyleProp,
  StyleSheet,
  TextStyle,
  ViewStyle,
} from 'react-native';

import COLORS from 'styles/colors';
import CONSTANTS from 'styles/constants';

import StyledText from './StyledText';

export enum ButtonTypes {
  OUTLINED,
  FILLED,
  TEXT,
  TRANSPARENT,
}

export enum ButtonColors {
  PRIMARY,
  SECONDARY,
  CONFIRM,
  GRAY,
  WHITE,
  TRANSPARENT,
}

export enum ButtonSize {
  SMALL,
  MEDIUM,
  LARGE,
}

export type StyledButtonProps = {
  size?: ButtonSize;
  fullWidth?: boolean;
  title: string;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  type?: ButtonTypes;
  color?: ButtonColors;
  children?: any;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
  fontWeight?: TextStyle['fontWeight'];
  onPress?: () => void;
};

function getBackground(type: ButtonTypes, color: ButtonColors): string {
  switch (true) {
    case type === ButtonTypes.FILLED && color == ButtonColors.PRIMARY:
      return COLORS.PRIMARY;
    case type === ButtonTypes.FILLED && color == ButtonColors.SECONDARY:
      return COLORS.SECONDARY;
    case type === ButtonTypes.FILLED && color == ButtonColors.CONFIRM:
      return COLORS.CONFIRM;
    case type === ButtonTypes.OUTLINED:
      return COLORS.WHITE;
    case type === ButtonTypes.TEXT:
      return COLORS.TRANSPARENT;
    case type === ButtonTypes.TRANSPARENT:
      return COLORS.TRANSPARENT;
    default:
      return COLORS.PRIMARY;
  }
}

function getBorderColor(color: ButtonColors): string {
  switch (color) {
    case ButtonColors.PRIMARY:
      return COLORS.PRIMARY;
    case ButtonColors.SECONDARY:
      return COLORS.SECONDARYBORDER;
    case ButtonColors.CONFIRM:
      return COLORS.CONFIRM;
    case ButtonColors.TRANSPARENT:
      return COLORS.TRANSPARENT;
    case ButtonColors.GRAY:
      return COLORS.DARK_GRAY;
    default:
      return COLORS.PRIMARY;
  }
}

function getText(type: ButtonTypes, color: ButtonColors): string {
  switch (true) {
    case type === ButtonTypes.FILLED && color === ButtonColors.SECONDARY:
      return COLORS.PRIMARY;
    case type === ButtonTypes.FILLED:
      return COLORS.WHITE;
    case color === ButtonColors.PRIMARY:
      return COLORS.PRIMARY;
    case color === ButtonColors.SECONDARY:
      return COLORS.SECONDARY;
    case color === ButtonColors.CONFIRM:
      return COLORS.CONFIRM;
    case color === ButtonColors.GRAY:
      return COLORS.DARK_GRAY;
    case color === ButtonColors.TRANSPARENT:
      return COLORS.BLACK;
    default:
      return COLORS.WHITE;
  }
}

function getBorderWidth(type: ButtonTypes): number {
  switch (type) {
    case ButtonTypes.TEXT:
      return 0;
    case ButtonTypes.FILLED:
    case ButtonTypes.OUTLINED:
      return 2;
    default:
      return 0;
  }
}

function StyledButton(props: StyledButtonProps): ReactElement {
  const {
    size = ButtonSize.MEDIUM,
    fullWidth = false,
    title,
    startIcon,
    endIcon,
    type = ButtonTypes.FILLED,
    color = ButtonColors.PRIMARY,
    children,
    style,
    disabled,
    fontWeight,
    onPress = () => {},
  } = props;

  const styles = StyleSheet.create({
    buttonBase: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: CONSTANTS.BORDER_RADIUS_ROUND,
    },
    buttonDisabled: {
      opacity: 0.5,
    },
    buttonBaseText: {
      fontWeight: fontWeight,
      textAlign: 'center',
    },
  });

  return (
    <Pressable
      style={[
        style,
        styles.buttonBase,
        {
          backgroundColor: getBackground(type, color),
          borderWidth: getBorderWidth(type),
          borderColor: getBorderColor(color),
        },
        disabled ? styles.buttonDisabled : undefined,
        //        fullWidth ? undefined : { alignSelf: 'stretch' },
        size === ButtonSize.MEDIUM
          ? { padding: 12 }
          : size === ButtonSize.SMALL
          ? { padding: 8 }
          : { padding: 16 },
      ]}
      onPress={onPress}
      disabled={disabled}
    >
      {startIcon}
      <StyledText
        style={[
          styles.buttonBaseText,
          { color: getText(type, color) },
          size === ButtonSize.MEDIUM
            ? { fontSize: 16 }
            : size === ButtonSize.SMALL
            ? { fontSize: 14 }
            : { fontSize: 18 },
        ]}
      >
        {title}
        {children}
      </StyledText>
      {endIcon}
    </Pressable>
  );
}

export default StyledButton;
