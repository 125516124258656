import MaterialIcon from '@expo/vector-icons/MaterialIcons';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useEffect, useState } from 'react';
import { SafeAreaView, StyleSheet, View } from 'react-native';

import BoidCard from 'components/BoidCard';
import PrimaryGradient from 'components/PrimaryGradient';
import RoundedButton from 'components/RoundedButton';

import { ExtendedBoidCardModel } from 'models/BoidCardModel';

import BoidCardService from 'services/boid-card/BoidCardService';

import { CollectionStackPropList } from './CollectionNavigation';

function CardDetailsScreen(props: StackScreenProps<CollectionStackPropList>) {
  const {
    navigation,
    route: { params },
  } = props;

  const [boidCard, setBoidCard] = useState<ExtendedBoidCardModel>();
  useEffect(() => {
    if (params?.boidCard) {
      setBoidCard(params.boidCard);
    }
  }, [params?.boidCard]);

  // TODO: Design this and add navigation from the other pages
  return (
    <SafeAreaView style={styles.page}>
      <PrimaryGradient />
      <RoundedButton
        style={styles.backButton}
        onPress={() => navigation.goBack()}
        icon={<MaterialIcon name="arrow-back" color={'white'} size={26} />}
      />

      <View style={styles.boidCardContainer}>
        {boidCard && <BoidCard boidCard={boidCard} />}
      </View>
    </SafeAreaView>
  );
}

export default CardDetailsScreen;

const styles = StyleSheet.create({
  page: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  boidCardContainer: {
    paddingTop: 12,
    width: '90%',
    height: '70%',
    justifyContent: 'center',
  },
  backButton: {
    position: 'absolute',
    top: 20,
    left: 15,
  },
});
