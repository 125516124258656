import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

// 4-piece scan frame for camera overlay

function ScanFrame(props: SvgProps) {
  return (
    <Svg viewBox="0 0 244 244" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55 0H8a8 8 0 0 0-8 8v43h6V8a2 2 0 0 1 2-2h47V0Zm136 6V0h45a8 8 0 0 1 8 8v43h-6V8a2 2 0 0 0-2-2h-45Zm0 232h45a2 2 0 0 0 2-2v-49h6v49a8 8 0 0 1-8 8h-45v-6ZM6 187v49a2 2 0 0 0 2 2h47v6H8a8 8 0 0 1-8-8v-49h6Z"
        fill="#fff"
      />
    </Svg>
  );
}

export default ScanFrame;
