import Config from 'config';

import { ExtendedBoidCardModel } from 'models/BoidCardModel';

import ConnectionService from 'services/connection/ConnectionService';
import { getUserProfile } from 'services/user/UserService';

async function getExtendedBoidCard(id: string): Promise<ExtendedBoidCardModel> {
  const { id: ignored, ...profile } = await getUserProfile(id);

  const extendedBoidCard: ExtendedBoidCardModel = {
    id,
    ...profile,
  };
  return extendedBoidCard;
}

async function getMyExtendedBoidCard(): Promise<ExtendedBoidCardModel> {
  const data = await getUserProfile('me');
  return data;
}

async function getMyCollectedBoidCards(): Promise<ExtendedBoidCardModel[]> {
  const connectionInfo = await ConnectionService.getConnections();
  const connections =
    connectionInfo?.map((cardId: { otherUserId: string }) =>
      getUserProfile(cardId.otherUserId),
    ) ?? [];
  return Promise.all(connections);
}
export default {
  getExtendedBoidCard,
  getMyExtendedBoidCard,
  getMyCollectedBoidCards,
};
