import { NavigationProp, useNavigation } from '@react-navigation/native';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  Image,
  Pressable,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import getCountryFromOffice from 'utilities/getCountryFromOffice';

import StyledText from 'components/StyledText';

import { ExtendedBoidCardModel } from 'models/BoidCardModel';

import UserService from 'services/user/UserService';

import COLORS from 'styles/colors';
import CONSTANTS from 'styles/constants';

import { CollectionStackPropList } from './CollectionNavigation';

export type CardItemProps = {
  style?: StyleProp<ViewStyle>;
  boidCard: ExtendedBoidCardModel;
};
function CardItem(props: CardItemProps): ReactElement {
  const { boidCard, style } = props;

  const navigation = useNavigation<NavigationProp<CollectionStackPropList>>();

  const { firstName, lastName, office } = boidCard;
  const country = useMemo(() => getCountryFromOffice(office), [office]);
  const [profileImage, setProfileImage] = useState<string>();
  useEffect(() => {
    if (boidCard) {
      UserService.getUserImage(boidCard.id).then((image) =>
        setProfileImage(image),
      );
    }
  }, [boidCard]);

  return (
    <Pressable
      style={[styles.cardContainer, style]}
      onPress={() => navigation.navigate('CardDetailsScreen', { boidCard })}
    >
      {/* <BoidCardBackground style={styles.background} /> */}
      <Image source={{ uri: profileImage }} style={styles.profileImage} />
      {firstName === 'ConnectingTheDots' ? (
        <View style={styles.nameContainer}>
          <StyledText style={styles.nameText}>Summit Fall 22</StyledText>
        </View>
      ) : (
        <View style={styles.nameContainer}>
          {/* <Flag countryCode={country} style={styles.flag} /> */}
          <StyledText style={styles.nameText}>{firstName}</StyledText>
          <StyledText style={styles.nameText}>{lastName}</StyledText>
        </View>
      )}
    </Pressable>
  );
}

export default CardItem;

const styles = StyleSheet.create({
  cardContainer: {
    flex: 1,
    borderRadius: CONSTANTS.BORDER_RADIUS,
    padding: 10,
    // backgroundColor: COLORS.PRIMARY + '30', // appending opacity value to a HEX color
    backgroundColor: '#F7F5FF',
    maxWidth: '100%',
    overflow: 'hidden',
    borderColor: COLORS.PRIMARY + '80',
    borderWidth: 3,
  },
  profileImage: {
    width: '100%',
    aspectRatio: 1,
    borderRadius: 50,
  },
  flag: {
    width: 14,
    aspectRatio: 1,
    borderRadius: 1000,
    marginRight: 4,
    borderWidth: 1,
    borderColor: 'white',
  },
  nameContainer: {
    marginTop: 10,
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameText: {
    fontSize: 14,
    color: 'black',
    fontWeight: '600',
    wordBreak: 'break-words',
    textAlign: 'center',
  },
  background: {
    ...StyleSheet.absoluteFillObject,
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
});
